@use "src/assets/styles/common/variables" as *;
@use "src/assets/styles/common/global" as *;
@use "src/assets/styles/common/mixins" as mixins;


.drawer {
  > *{
    @include mixins.scrollbar("!important");
  }
}

.mainSidebar {
  position: relative;
  min-height: 100vh;
  max-height: 100%;
  overflow: hidden;
  max-width: 261px;
  min-width: 261px;
  border: 1px solid $lightest-grey;
  box-sizing: border-box;
  border-radius: 0 15px 15px 0;
  box-shadow: -7px 0 25px -7px rgba(0, 0, 0, 0.4);
}

.activeItems {
  background: rgba(0, 160, 155, 0.19);
  >h3 {
    font-weight: bold;
    color: $merge-turquoise !important;
  }
  svg {
    path{
      fill: $merge-turquoise
    }
  }
}

.sidebar {
  @extend .mainSidebar;
  @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {
    max-width: 166px;
    min-width: 166px;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
  }

  @media screen and (min-width: $small-width + 201px) and (max-width: $medium-width) {
    max-width: 239px;
    min-width: 239px;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
  }

  .logo {
    margin-top: 82px;
    @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {
      margin-top: 49px;
      justify-content: space-evenly;
    }
    @extend .center;

    .logoIcon {
      width: 140px;
      height: 30px;
      @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {
        width: 76px;
        height: 16px;
      }
    }
  }

  .footerMenu {
    @extend .column;
    @include mixins.absolute(unset, 17px, 136px, 20px);
    @media screen and (max-width: $medium-width) {left: 4px; right: 4px}

    .menuItems {
      position: relative;
      width: 100%;
      border-radius: 10px;
      @include mixins.paddingVertical(8px);
      @extend .align-items-center;
      @extend .transparent;

      >h3 {
        @include mixins.font(20px, 16px);
        @include mixins.fontWeight(500);
        position: absolute;
        left: 65px;
        color: $darkest-blue;
        @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {left: 40px;}
      }
      .menuItemsIcon {
        margin-left: 30px;
        margin-right: 15px;
        @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {margin-left: 10px;}
      }
    }
    .menuItemsActive { @extend .activeItems;}
  }

  .menu {
    @extend .column;
    margin: 56px 17px 0 20px;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {margin-left: 7px; width: 143px}
    @media screen and (min-width: $small-width + 201px) and (max-width: $medium-width) {@include mixins.marginHorizontal(4px)}
    .dotted {
      @include mixins.absolute(30px, unset, unset, 19px);
    }
    .menuItems {
      transition: all 0.25s;
      -webkit-transition: all 0.25s;
      @include mixins.paddingVertical(8px);
      @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) { @include mixins.paddingVertical(7px);}
      @extend .transparent;
      @extend .align-items-center;
      border-radius: 10px;
      width: 100%;
      position: relative;
      >h3 {
        transition: all 0.25s;
        -webkit-transition: all 0.25s;
        position: absolute;
        left: 65px;
        color: $darkest-blue;
        @include mixins.font(20px, 16px);
        @include mixins.fontWeight(500);
        @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {left: 40px;}
      }
      .menuItemsIcon {
        margin-left: 30px;
        margin-right: 15px;
        @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {margin-left: 10px;}
      }
      .caretDown {
        right: 25px;
        position: absolute;
        @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {right: 6px;}
      }
    }
    .menuItemsActive { @extend .activeItems }
    .submenu {
      margin-left: 60px;
      @media screen and (max-width: $medium-width) {margin-left: 30px;}
      @media screen and (min-width: $small-width) and (max-width: $small-width + 200px) {margin-left: 15px; min-width: 140px}

      @extend .align-items-center;
      @extend .transparent;
      border-radius: 10px;
      padding-left: 15px;

      >h3 {
        @include mixins.paddingVertical(8px);
        margin-left: 17px;
        color: $darkest-blue;
        @include mixins.font(20px, 16px);
        @include mixins.fontWeight(500);
      }
    }
    .submenuActive { @extend .activeItems }
  }
}