@use "src/assets/styles/common/mixins.scss" as mixins;
@import "src/assets/styles/global.module";
@import "src/assets/styles/common/functions";
@import "src/assets/styles/common/variables";


.send-funds {
  padding-top: rem(17);
  padding-left: rem(31);
  padding-right: rem(31);
  @media screen and (min-width: 580px) and (max-width: 899px) {padding: rem(32);}
  @media screen and (max-width: 579px) {
    padding-top: rem(24);
    padding-left: rem(18);
    padding-right: rem(18);
  }

  .inputLoading {
    svg {
      height: 50px;
    }
  }

  .reviewLoading {
    svg {
      height: 25px;
      width: 25px;
    }
  }

  .list {
    padding-left: 0;
  }
  .page-title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: rem(32);
    line-height: rem(36);
    @media screen and (max-width: 899px) {
      font-size: rem(20);
      line-height: rem(24);
    }
  }
  .available-amount {
    color: $dark-grey;
    font-size: 18px;

    span {
      &.amount {
        font-weight: 500;
        line-height: 20px;
        color: $merge-turquoise;
        font-size: 18px;
        @media screen and (max-width: 899px) {font-size: rem(16);}
      }
    }
  }
  .fee-information {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    color: $dark-grey;

    span {
      &.amount {
        color: $merge-turquoise;
      }
    }
  }
  .stepper-wrapper {
    margin: 30px 0px;
    .stepper-connector {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        position: absolute;
        top: 12px;
        left: calc(-50% + 20px);
        right: calc(50% + 20px);

      span {
         display: block;
         border-color: #bdbdbd;
         border-top-style: dashed;
         border-top-width: 1px;
      }
    }
    .Mui-completed {
      &.MuiStepLabel-label {
        color: $merge-turquoise;
      }
    }
  }
  .label {
    color: $darkest-blue;
    margin-bottom: 5px;
    margin-top: 16px;
    @extend .align-items-center;
    @include mixins.font(19px, 16px);
    @media (max-width:#{$small-width + 280px}){ margin-bottom: 11px}
  }

  .recipientAmount {
    font-size: 24px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }

  .amountInput {
    margin-top: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    color: $dark-grey;
    font-size: rem(24);
    line-height: rem(30);
    @media screen and (max-width: 899px) {
      font-size: rem(20);
      line-height: rem(24);
    }
  }
  
  .stepper-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    color: $dark-grey;
    font-size: rem(24);
    line-height: rem(30);
    @media screen and (max-width: 899px) {
      font-size: rem(20);
      line-height: rem(24);
    }
  }

  .review-edit-icon {
    float: right;
    color: $merge-turquoise;
    margin-bottom: 1px ;
    text-decoration: underline;
    cursor: pointer;

    svg {
      fill: $merge-turquoise;
      margin-left: 10px;
      path {
        fill: $merge-turquoise;
      }
    }
  }

  .list-wrapper {
     max-height: rem(470);
     overflow-y: auto;
    &::-webkit-scrollbar {
       width: 0.2em;
     }

     &::-webkit-scrollbar-track {
       box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     }

     &::-webkit-scrollbar-thumb {
       background-color: darkgrey;
     }
     .list {
       border-top: 1px solid $light-grey;
       cursor: pointer;
       &:hover {
         background: rgba(207, 237, 236, 0.2);
       }
       &:last-child {
         border-bottom: 1px solid $light-grey;
       }
     }
   }

  .review-step {
    .review-sub-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: rem(18);
      line-height: rem(20);
      margin: rem(16) 0 rem(26);
    }
    .review-detail-label {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: rem(18);
      line-height: rem(20);
      color: $dark-grey;
      margin-bottom: rem(16);
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 480px) {font-size: 14px;}
    }
    .review-detail-item {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: rem(16);
      line-height: rem(20);
      color: $darkest-blue;
      margin-left: rem(10);
      word-break: break-all;
      text-align: right;
      &.error {
        color: $error-red
      }
      @media screen and (max-width: 480px) {font-size: 14px;}
    }
  }
  .confirm-step {
    text-align: center;
    .txnSuccess {
      line-height: rem(30);
      color: $darkest-blue;
      margin: 10px 0px 65px 0px;
      h1 {
        color: #111326;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.875rem;
      }
      .txnInfo {
        margin-top: 20px;
        font-size: 1rem;
      }
    }

    .confirm-title {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: rem(24);
      line-height: rem(30);
      color: $darkest-blue;
      margin-bottom: rem(65);
    }
    .confirm-title-error {
      font-family: Inter, sans-serif;
      font-weight: 200;
      font-style: normal;
      font-size: rem(24);
      line-height: rem(30);
      color: $darkest-blue;
      margin-bottom: rem(65);
    }
  }
  .amount-field {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding-left: 0px;
  }

  .error-icon {
    color: $error-red;
    svg & {
      fill: $error-red;
      color: #F09;
      path {
        fill: $error-red;
      }
    }
  }
  .error {
    color: $error-red;
    font-weight: 400;
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
  }
  @media screen  and (max-width: 480px){
    .submitButton {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
  .submitButton {
    width: 33%;
    margin-left: 33%;
    margin-bottom: 50px;
  }
  .another {
    @media screen and (max-width: 480px) {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }
  }
}

.avatar {
  width: rem(47);
  height: rem(47);
  background: $merge-turquoise-light !important;
  color: $merge-turquoise !important;
}


.fundsLoading {
  position:fixed;
  top:0;
  left: 0;
  opacity: 0.7;
  margin-left: 260px;
  z-index: 10000;
  height: 100%;
  width: 650px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 80px;
    width: 80px;
  }

  //@media screen and (min-width: $medium-width - 34px) and (max-width: $medium-width) {
  //  width: calc(100vw - 240px);
  //}

  @media screen and (max-width: 900px) {
    margin-left: 0;
    width: 100%
  }
}

.divider {
  width: 50%;
  height: 1px;
  border-top: 1px solid #e5e5e5;
  margin: 50px 0px 15px;
  margin-left: 25%;
}

.paymentActions {
  display: flex;
  justify-content: center;
  padding: 16px;
  .actionBtn {
    width: 240px;
    padding: 5px;
    border-radius: 15px;
  }
}
