@use "common/variables" as *;
@use "common/global" as *;
@use "common/mixins" as mixins;

.background {
  //overflow: hidden;
  position: relative;
  width: 100%;
  //height: 100vh;
  &__image {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 75%;
    @media screen and (min-width: $medium-width + 1px){
      background: url("files/Line-Circle.png") no-repeat center center;
    }
    @media screen and (min-width: $small-width + 281px) and (max-width: $medium-width){
      background: url("files/Line-Circle-Ipad.png") no-repeat center center;
      height: 55%;
    }
    @media screen and (min-width: $small-width) and (max-width: $small-width + 280px) {display: none;}
  }

  &__content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 112px auto 0 auto;
    @media screen and (min-width: $large-width - 201px) and (max-width: $large-width) {
      @include mixins.marginHorizontal(129px);
    }
    @media screen and (min-width: $large-width - 301px) and (max-width: $large-width - 200px) {
      @include mixins.marginHorizontal(60px);
    }
    @media screen and (min-width: $large-width - 416px) and (max-width: $large-width - 300px) {
      @include mixins.marginHorizontal(32px);
    }
    @media screen and (max-width: $large-width - 417px) {
      flex-direction: column;
      margin-top: 80px;
    }
    @media (min-width: $small-width) and (max-width: $small-width + 180px) {
      margin-top: 40px
    }

    .container {
      @media screen and (max-width: $large-width - 417px) {
        margin-left: 42px;
      }

      > svg {
        width: 140px;
        height: 30px;
        @media (max-width: $small-width + 150px) and (max-width: $medium-width - 1px) {
          width: 76px;
          height: 16px
        }
      }

      .title {
        margin-top: 82px;
        color: $darkest-blue;
        @include mixins.font(48px, 48px, Inconsolata);
        @include mixins.fontWeight(500);

        > span {
          font-weight: 500 !important;
          margin-right: 10px;
          &.green {
            @include mixins.font(48px, 48px, Inconsolata);
            color: $merge-turquoise;
          }
          &.gray {
            @include mixins.font(48px, 48px, Inconsolata);
          }
        }

        @media (max-width: $medium-width + 190px) {
          display: none
        }
      }

      .info {
        margin-top: 72px;
        padding-left: 4px;

        .item {
          @extend .align-items-center;
          margin-top: 16px;

          > svg {
            cursor: default !important;
            min-width: 21px;
            min-height: 21px;
          }

          > p {
            margin-left: 18px;
            max-width: 286px;
            color: $merge-turquoise;
            @include mixins.font(24px, 18px);
            @include mixins.fontWeight(normal)
          }
        }

        @media (max-width: $medium-width + 190px) {
          display: none
        }
      }

      .terms {
        @media (min-width: $small-width) and (max-width: $small-width + 280px) {display: none;}
        position: absolute;
        bottom: 20px;

        > button {
          margin-right: 20px;
          color: $darkest-blue;
          @extend .transparent;
          @include mixins.font(unset, 16px);
          @include mixins.fontWeight(normal);
        }
      }
    }

    .children {
      margin-top: 112px;
      margin-bottom: 212px;
      @media screen and (max-width: $large-width - 417px) {
        margin-top: 0
      }
      @media (min-width: $small-width) and (max-width: $small-width + 280px) {
        margin-bottom: 0;
      }
    }
  }
}

.small {
  .curveSM{
    width: 100%;
    display: none;
    cursor: default !important;
    @media (min-width: $small-width) and (max-width:$small-width + 280px) {
      display: block;
      margin-top: -90px;
      width: 100%;
    }
  }
  .terms {
    display: none;
    @media (min-width: $small-width) and (max-width: $small-width + 280px) {
      margin-top: -60px;
      margin-bottom: 20px;
      margin-left: 16px;
      display: block;
    }
    >button {
      margin-left: 20px;
      color: $darkest-blue;
      @extend .transparent;
      @include mixins.font(unset, 16px);
      @include mixins.fontWeight(normal);
    }
  }
}

.circleGradient {
  position: absolute;
  bottom: 6.3%;
  left: 23.5%;
  display: none;
  @media (min-width: $small-width) and (max-width:$small-width + 280px) {display: block;}
  @media (min-width: 541px) and (max-width:570px) { left: 22%;}
  @media (min-width: 511px) and (max-width:540px) { left: 20%;}
  @media (min-width: 481px) and (max-width:510px) { left: 18%;}
  @media (min-width: 451px) and (max-width:480px) { left: 15%;}
  @media (min-width: 421px) and (max-width:450px) { left: 13%;}
  @media (min-width: 391px) and (max-width:420px) { left: 11%;}
  @media (min-width: 361px) and (max-width:390px) { left: 8%;}
  @media (min-width: 341px) and (max-width:360px) { left: 6%;}
  @media (min-width: 321px) and (max-width:340px) { left: 2%;}
}

