@use "common/variables" as *;
@use "common/global" as *;
@use "common/functions" as *;
@use "common/input" as *;
@use "common/mixins" as *;

.fontTwenty {
  font: 20px Inter;
  line-height: 20px;
}

@mixin size($val) {
  font: $val Inter;
}

@mixin bordered($color, $width) {
  border: $width solid $color;
}

.outlined {
  border: 1px solid #00A09B;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 15px;
  padding: 12px;
  width: 100%;
  margin-top: 32px;
  >p {
    font:18px Inter;
    line-height: 26px;
    color: #00A09B;
  }
}

html,
body {
  min-height: 100vh;
  @include scrollbar();
}



// new
.title {
  font: 32px Inter;
  line-height: 39px;
  font-weight: normal;
  font-style: normal;
  color: $darkest-blue;
}

.paragraph {
  font: 18px Inter;
  font-weight: normal;
  font-style: normal;
  color: $darkest-blue
}

.grayShadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background-color: $white;
  border: 1px solid $white;
  box-sizing: border-box;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexAlignCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;

}

.full {
  width: 100%;
  height: 100%;
}

.button {
  border-radius: 15px;
  border-radius: 15px;
  padding: rem(7) rem(40);
}

.transparentButton {
  border: none;
  outline: none;
  color: $merge-turquoise;
  background-color: transparent
}

.outlined-button {
  border: 1px solid $merge-turquoise;
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  color: $merge-turquoise;
  padding: rem(7) rem(40);

  &:hover {
    background: $button-gradient;
    color: $white;
    border: inherit;
  }
}

.submitButton {
  border: none;
  outline: none;
  background: $button-gradient;
  border-radius: 15px;
  padding: 12px;
  width: 100%;
  //margin-top: 32px; // check other pages
  >p {
    font-size: 20px;
    line-height: 26px;
    color: $white;
  }
}

.label {
  font: 16px Inter;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: $darkest-blue;
  margin-bottom: 5px;
  margin-top: 16px;
}

.businessMargins {
  margin-top: 72px;
  margin-left: 90px;
}

.disable {
  font: 16px Inter;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 16px;
  color: $light-grey;
}
.title-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: rem(52);
}

.icon-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
