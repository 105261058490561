@use "src/assets/styles/common/variables.scss" as *;
@use "src/assets/styles/common/mixins.scss" as mixins;
@use "src/assets/styles/common/global.scss" as *;
@use "src/assets/styles/common/functions.scss" as *;

.list {
  margin-top: 15px;

  @media screen and (max-width: 899px) {
    margin-top: 56px
  }

  .subItem {
    height: 84px;

    @media screen and (max-width: 880px) {
      height: 112px;

      div:nth-child(2) {
        margin-bottom: 40px;
      }
    }

    @media screen and (max-width: 540px) {
      height: 140px;

      div:nth-child(2) {
        margin-bottom: 18px;
      }
    }

    @media screen and (max-width: 440px) {
      height: 150px;

      div:nth-child(2) {
        margin-bottom: 36px;
      }
    }
  }

  .createdDate {
    @include mixins.font(20px, 14px);
    @include mixins.fontWeight(600);
    color: $light-grey;
    margin-bottom: 24px;
    margin-top: 8px;
  }

  .accountBalance {
    margin-right: 30px;
    font-weight: 600;

    span {
      font-weight: 600 !important;
      font-size: 14px !important;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    span,
    button {
      @include mixins.font(22px, 18px);
    }

    &Icon {
      @include mixins.iconStyles($merge-turquoise);
    }

    &IconSandbox {
      @include mixins.iconStyles($sandbox-orange);
    }
  }
}

.item {
  height: 96px;
  border-top: 1px solid $lightest-grey;
  font-size: 14px;

  @media screen and (max-width: 580px) {
    height: 127px;
  }

  p {
    @media screen and (max-width: 540px) {
      max-width: 160px;
      margin-bottom: 18px;
    }
  }

  .type {
    right: 180px;
    position: absolute;
    width: 200px;
    text-align: right;
    @include mixins.font(22px, 18px);

    @media screen and (max-width: 580px) {
      text-align: left;
      left: 73px;
      top: 90px
    }
  }

  .subType {
    @extend .type;
    right: 180px;
  }

  .avatar {
    width: 47px;
    height: 47px;
    background: $merge-turquoise-light;
    color: $merge-turquoise;
    margin-right: 17px;
    font-size: 16px;

    &Disable {
      color: #777777;
    }
  }

  &:last-child {
    border-bottom: 1px solid $lightest-grey;
  }

  .action {
    margin: 0 20.5px;

    svg {
      vertical-align: middle;

      path {
        fill: #00A09B;
      }
    }

    @media screen and (max-width: 580px) {
      margin-top: 50px;
    }

    @media screen and (max-width: 400px) {
      margin-right: 0;
    }
  }

  .statusIcon {
    position: absolute;
    right: 105px;

    @media screen and (max-width: 680px) {
      right: 60px;
    }

    @media screen and (max-width: 580px) {
      top: 45px;
      right: 31px;
    }

    @media screen and (max-width: 400px) {
      right: 10px;
    }
  }

  .subStatusRef {
    position: absolute;
    left: 46%;
    width: 35%;
    top: 30px;
  }

  .subStatusRefNoData {
    @extend .subStatusRef;
    top: 30px
  }
}

.search {
  width: 315px;
  margin-right: 13px;

  input {
    height: 40px;
  }
}

.searchField {
  margin-top: 12px;
  max-width: 216px;
  box-sizing: border-box;
  border-radius: 15px;
  border: none !important;
  box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));

  >div {
    max-height: 0 !important;
    padding: 20px 0;

    >div {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 550px) {
    max-width: 100%;
  }
}

.statusField {
  @extend .searchField;
  margin-top: 0;
  margin-right: 32px;
  min-width: 216px;
  max-width: 216px;

  @media screen and (min-width: 820px) and (max-width: 879px) {
    min-width: 200px;
    max-width: 200px;
  }

  @media screen and (max-width: 820px) {
    min-width: 100%;
  }
}

.container {
  padding: 17px 31px;

  @media screen and (max-width: 520px) {
    padding-left: 18px;
    padding-right: 18px !important
  }

  @media screen and (max-width: 899px) {
    padding-top: 25px;
    padding-right: 31px
  }

  .header {
    display: flex;
    gap: 10px;
    align-items: center;
    .title {
      color: $darkest-blue;
      @include mixins.font(36px, 32px);
  
      @media screen and (max-width: 460px) {
        @include mixins.font(24px, 20px);
      }
    }
  }

  .loading {
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 80px;
    display: flex;
    align-items: center;
  }

  .status {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 820px) {
      width: 75%;
      margin-right: 20px;
    }
  }

  .balance {
    margin-top: 56px;
    margin-right: 20px;

    @media screen and (max-width: 484px) {
      margin-top: 40px;
    }

    .refresh {
      display: flex;
      align-items: center;

      >h3 {
        color: $dark-grey;
        @include mixins.font(29px, 24px);
        @include mixins.fontWeight(500);
      }

      svg {
        margin-left: 8px;
        margin-top: 4px;
      }
    }


    >h2 {
      margin-top: 16px;
      color: $darkest-blue;
      @include mixins.font(39px, 32px);
      @include mixins.fontWeight(600);
    }

    >h5 {
      color: $dark-grey;
      margin-top: 16px;
      @include mixins.font(19px, 16px);
    }
  }

  .copy {
    margin-top: 73px;
    position: relative;
    max-width: fit-content;

    @media screen and (max-width: 484px) {
      margin-top: 40px;
    }

    >p {
      color: $darkest-blue;
      margin-bottom: 8px;
      @include mixins.font(24px, 18px);

      @media screen and (max-width: 340px) {
        font-size: 16px
      }

      >span {
        margin-right: 6px;
      }

      &:first-child {
        display: flex;
        align-items: center;

        .copied {
          color: #00A09B;
          position: absolute;
          font-size: 12px;
          right: -50px;
          top: -15px
        }
      }
    }

    // .copyIcon {
    // margin-left: 89px;
    // }
  }

  .search {
    max-width: 773px;
    height: 40px;
  }

  .clear {
    position: absolute;
    right: 210px;
    top: 13px
  }

  .accountsClear {
    position: absolute;
    right: 50px;
    top: 52px
  }

  .noData {
    color: $dark-grey;
    font-weight: 500;
    margin: 100px auto
  }

  .searchButton {
    height: 40px;
    padding: 7px 54px;
    width: 166px;
    margin: 0px 5px;
    @include mixins.font(26px, 18px);

    @media screen and (max-width: 420px) {
      padding: 7px 18px;
    }
  }

  .statistic {
    margin-top: 56px;
    margin-right: 44px;
    min-width: 504px;
    max-width: 504px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    .balance {
      min-width: fit-content;
      margin: 64px 56px 44px 56px;

      .refresh {
        width: 326px;

        >h3 {
          color: $dark-grey;
          @include mixins.font(29px, 24px);
          @include mixins.fontWeight(500);
        }

        svg {
          margin-left: 18px;
          margin-top: 4px;
        }
      }

      h2 {
        margin-top: 8px
      }

      h5 {
        margin-top: 16px;
      }
    }

    .area {
      margin: 12px;
      position: relative;

      .periods {
        border-bottom: 1px solid $lightest-grey;
        margin: 72px 15px 24px 18px;
        padding-bottom: 13px;
        display: flex;
        justify-content: space-around;

        .items {
          background-color: transparent;
          border: none;
          outline: none;
          font: 16px Inter;
          font-style: normal;
          font-weight: normal;
          line-height: 19px;
          color: #777777;

          &.active {
            background: #E5E5E5;
            padding-left: 11px;
            padding-right: 11px;
            border-radius: 15px;
          }
        }
      }
    }

    .view {
      margin-top: 15px;
      margin-left: 29px;
      margin-bottom: 44px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      @include mixins.font(19px, 16px);
      color: $dark-grey;
    }
  }

  .tabs {
    max-width: 423px;
    min-width: 423px;
    margin-top: 56px;

    .transactions {
      display: flex;
      justify-content: space-between;

      >button {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        @include mixins.font(19px, 16px);
        color: $dark-grey;
      }

      h3 {
        @include mixins.font(29px, 24px);
        font-weight: 500;
        color: $dark-grey;
      }
    }
  }
}

.collapsible {
  height: 100%;
  padding: 0 7px 40px 7px;
  background-color: #fff;
  overflow: hidden;
  margin-left: 12px;

  @media screen and (max-width: 899px) {
    padding: 0;
  }

  h3 {
    color: $darkest-blue;
    @include mixins.font(24px, 18px);
    @include mixins.fontWeight(500);
  }

  .details {
    margin-top: 32px;
    padding-right: 49px;

    @media screen and (max-width: 899px) {
      width: 100%;
    }

    @media screen and (max-width: 400px) {
      padding-right: 25px;

      h3 {
        @include mixins.font(24px, 16px);
      }

      p {
        @include mixins.font(20px, 14px);
      }
    }

    .row {
      width: 100%;
      display: flex;

      .record {
        display: flex;
        padding: 10px;

        .label {
          width: 150px;
          color: #777777;
          font-weight: 700;
        }
      }
    }
  }

  .info {
    margin-top: 32px;
    padding-left: 40px;
    padding-right: 32px;

    @media screen and (max-width: 899px) {
      padding-left: 0;
      width: 100%;
    }

    @media screen and (max-width: 400px) {
      padding-right: 25px;

      h3 {
        @include mixins.font(24px, 16px);
      }

      p {
        @include mixins.font(20px, 14px);
      }
    }
  }

  .paymentType {
    font-size: 20px;
    font-weight: 700;
    color: rgba(17, 17, 17, 1);
  }
  .summary {
    display: flex;

    .accountInfo {
      width: 35%;
      min-width: 340px;
      min-height: 100px;
      border-radius: 15px;
      padding: 15px;
      background-color: rgba(251, 251, 251, 1);

      .txndetails {
        display: flex;
        align-items: center;

        .card {
          margin-left: 10px;

          .label {
            font-size: 14px;
            line-height: 20px;
            color: rgba(102, 102, 102, 1);
            font-weight: 500;
          }

          .value {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
          }
        }
      }

      .amountInfo {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;

        .label {
          font-size: 12px;
          line-height: 16px;
          font-weight: 700;
          color: rgba(102, 102, 102, 1);
        }

        .amount {
          line-height: 25px;
          font-size: 20px;
          font-weight: 600;
        }

        .time {
          line-height: 25px;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .identifiersInfo {
        margin-top: 20px;

        .identifier {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 5px;

          .label {
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            color: rgba(102, 102, 102, 1);
          }

          .value {
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: rgba(102, 102, 102, 1);
          }
        }
      }
    }

    .transferInfo {
      width: 30%;

      .transferLine {
        border-top: 1px dashed #bbb;
      }

      .txnStatus {
        margin-top: -20px;
        height: 30px;
        padding-top: 10px;
        width: 160px;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        border-radius: 6px;
        margin-left: auto;
        margin-right: auto;
      }

      .successful {
        background-color: #EBF7F7;
        color: $merge-turquoise;
      }

      .processing {
        background-color: #FEF5E6;
        color: rgb(204, 123, 3);
      }

      .failed {
        background-color: #FFEFEF;
        color: rgba(255, 49, 49, 1);
      }

      .exchangeRate {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        color: rgb(17, 17, 17);
      }
      .quoteId {
        background-color: rgba(245, 247, 255, 0.3);
        border: 1px solid rgba(112, 123, 162, 1);
        border-radius: 8px;
        width: fit-content;
        margin: 10px auto 0px auto;

        .header {
          text-align: left;
          margin-top: -6px;
          margin-left: 10px;
          width: fit-content;
          font-size: 10px;
          font-weight: 700;
          background: #fff;
          color: rgba(112, 123, 162, 1);
        }
        .value {
          text-align: center;
          color: rgba(146, 154, 182, 1);
          font-size: 12px;
          padding: 12px;
          font-weight: 500;
        }
      }
    }
  }

  .misc {
    margin-top: 30px;
    display: flex;

    .panel {
      width: 50%;
      padding: 0px 20px;
      margin: 0px 10px;
      border-radius: 6px;
      border: 1px solid rgba(229, 229, 229, 1);

      .createdAt {
        margin-top: -7px;
        width: 200px;
        background: #fff;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        color: #666666;
      }

      .label {
        font-size: 16px;
        font-weight: 500;
        padding: 5px 0px;
        color: rgba(102, 102, 102, 1);
        line-height: 28px;
      }

      .value {
        font-size: 14px;
        font-weight: 500;
        padding: 5px 0px;
        color: rgba(17, 17, 17, 1);
        line-height: 28px;
      }
    }
  }

  .feeSummary {
    display: flex;

    .feeDetails {
      padding: 20px;
      width: 60%;
      background-color: rgba(251, 251, 251, 1);

      .accDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .card {
          margin-left: 10px;

          .label {
            font-size: 14px;
            line-height: 20px;
            color: rgba(102, 102, 102, 1);
            font-weight: 500;
          }

          .value {
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
          }
        }

        .status {
          padding: 10px 20px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;

        }

        .successful {
          background-color: rgba(0, 160, 155, 0.08);
          color: $merge-turquoise;
        }

        .processing {
          background-color: rgb(254, 245, 230);
          color: rgb(204, 123, 3);
        }

        .failed {
          background-color: rgba(255, 49, 49, 0.08);
          color: rgba(255, 49, 49, 1);
        }

      }

      .txnInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
        margin-top: 20px;

        .data {
          display: flex;
          flex-direction: column;
          height: 44px;

          .label {
            height: 20px;
            font-size: 12px;
            font-weight: 700;
            line-height: 12px;
            color: rgba(102, 102, 102, 1);
          }

          .time {
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: rgba(17, 17, 17, 1);
            height: 20px;
          }

          .amount {
            height: 20px;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            color: rgba(17, 17, 17, 1);
          }
        }

      }

      .identifiersInfo {
        margin-top: 20px;

        .identifier {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 5px;
          height: 45px;

          .label {
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            color: rgba(102, 102, 102, 1);
          }

          .value {
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: rgba(102, 102, 102, 1);
          }
        }
      }
    }

    .miscFee {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .panel {
        padding: 10px 20px;
        margin-left: 10px;
        min-height: 120px;
        border-radius: 6px;
        border: 1px solid rgba(229, 229, 229, 1);
        align-content: center;
        height: 100%;

        .divider {
          display: block;
          height: 1px;
          border: 0;
          border-top: 0.2px solid #BBBBBB;
          padding: 0;
          margin: 10px 0px; 
        }

        .label {
          font-size: 16px;
          font-weight: 500;
          padding: 5px 0px;
          color: rgba(102, 102, 102, 1);
          line-height: 28px;
        }

        .value {
          font-size: 14px;
          font-weight: 500;
          padding: 5px 0px;
          color: rgba(17, 17, 17, 1);
          line-height: 28px;
        }
      }
    }
  }
}

.noTransactions {
  margin: 58px auto 0 auto;
  text-align: center;

  svg {
    path {
      fill: $light-grey;
    }
  }

  p {
    margin-top: 22px;
    @include mixins.font(20px, 16px);
    font-weight: 600;
    color: $light-grey;

  }
}

.date {
  width: 100%;
  font: 16px Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $light-grey;
  margin-top: 24px;
  padding-bottom: 6px;
  border-bottom: 1px solid $lightest-grey;
}

.accountTransactions {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;

  >h3,
  >h4 {
    font: 18px Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: $darkest-blue;
  }
}

.filter {
  margin-top: 30px;
  margin-bottom: 0px;

  &Container {
    min-width: 216px;
    padding-right: 15px !important;
  }

  &Wrapper {
    min-width: 156px;
    padding-right: 15px !important;
  }

  &Currency {
    width: 340px;
    padding-right: 15px !important;
  }

  &Search {
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 20px;
  }

  &Buttons {
    margin-top: 46px;
    margin-bottom: 32px;
    margin-left: 6px;

    button {
      border-radius: 15px;
      padding: 8px 15px;
      border: 1px solid $merge-turquoise;
      background-color: transparent;
      color: $merge-turquoise;
      margin-left: 8px;
      font-size: 18px;

      &:hover {
        background: rgba(0, 160, 155, 0.19)
      }

      &:active {
        filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25))
      }
    }
  }
}

.sandboxIcon {
  @include mixins.iconStyles($sandbox-orange);
}

@media screen and (max-width: 550px) {
  .filter {
    &Container {
      min-width: 100%;
    }

    &Picker {
      width: 100%;

      >div {
        display: block;
      }
    }

    &Buttons {
      min-width: 100%;
      display: flex;
      justify-content: center;
      margin-left: 0;

      button {
        min-width: 136px;
      }
    }
  }
}