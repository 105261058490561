@use "src/assets/styles/common/variables.scss" as *;
@use "src/assets/styles/common/mixins.scss" as mixins;
@use "src/assets/styles/common/global.scss" as *;
@use "src/assets/styles/common/functions.scss" as *;

.container {
    padding: 17px 31px;
    display: flex;
    flex-direction: column;
  
    .title {
      color: $darkest-blue;
      @include mixins.font(36px, 32px);
    }

    .searchButton {
      width: 200px;
      border: 1px solid #fff;
      color: #fff;
      @include mixins.font(26px, 18px);
      @media screen and  (max-width: 420px) {padding: 7px 18px;}
    }  
    .clearButton {
      width: 200px;
      @include mixins.font(26px, 18px);
      @media screen and  (max-width: 420px) {padding: 7px 18px;}
    }  
}

.filterV3{
  box-shadow: 0px 0px 20px 4px rgba(153, 153, 153, 0.1);
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-radius: 16px;
  .row {
    display: flex;
    margin: 0px 24px;
    padding: 16px 0px;
    border-bottom: 0.5px solid rgba(187, 187, 187, 0.5);
    &:last-child {
      border-bottom: 0px;
    }
    .left {
      width: 60%;
      padding-right:24px;
    }
    .right {
      border-left: 0.5px solid rgba(187, 187, 187, 0.5);
      padding-left: 24px;
      width: 40%;
      .txnType {
        max-width: 350px;
      }
      .txnStatus {
        max-width: 350px;
      }    
    }
    .sourcePicker {
      display: flex;
      height: 270px;
      flex-direction: column;
    }
  }
}

.filterV2 {
  display: flex;
  gap: 100px;
  margin-top: 30px;
  justify-content: space-between;
  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-around;
  }
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
}

.filter {
  display: flex;
  width: 100%;
  gap: 30px;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  .txnType {
    width: 25%;
    max-width: 350px;
  }
  .txnStatus {
    width: 25%;
    max-width: 350px;
  }
  .txnDatePicker {
    width: 50%;
    height: 96px;
  }
  .accountPicker {
    width: 50%;
  }
  .currencyPicker {
    width: 50%;
  }
  .format {
    width: 50%;
  }
  .actions {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;  
  }
}

.modal-content {
  text-align: center;
  .message {
    &-pending {
      font-size: 18px;
      margin-top: 30px;
      line-height: 32px; 
      color: #FF9431;
      text-align: center;
    }
    &-success{
      font-size: 18px;
      margin-top: 30px;
      line-height: 32px;  
      color: #00A09B;
      text-align: center;
    }
    &-failed{
      font-size: 18px;
      margin-top: 30px;
      line-height: 32px;  
      color: #FF3131;
      text-align: center;
    }
  }
}

.reportSearchField {
  margin-top: 12px;
  // max-width: 250px;
  box-sizing: border-box;
  border-radius: 15px;
  border: none !important;
  box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));
  >div {
    max-height: 0 !important;
    padding: 20px 0;
    >div {
      padding: 0 20px;
    }
  }
  // @media screen and (max-width: 550px) {max-width: 100%;}
  // .indeterminateColor{
  //   color: #f50057;
  // }
}

.reportSelectFilter {
  margin-top: 12px;
  box-sizing: border-box;
  border-radius: 15px;
  border: none !important;
}

.accountName {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;

  img {
    width: 25px;
    height: 18px;
    border-radius: 3px;
  }

  p {
    font-size: 0.875rem !important; 
    font-weight: 700 !important;
  }
}

.iconInfo {
  div {
    margin-left: 0 !important;
    width: fit-content !important;
  }
}

.filterItem {
  display: grid;
  gap: 14px 24px;
  grid-template-columns: repeat(3, minmax(auto, 357px));
  margin: 8px 0 37px;
}

.actionBtnWrapper {
  display: flex;
  justify-content: space-between;
  height: 40px;
  gap: 20px;
  margin-top: 3px; 
}

.tabsContainer {
  margin-top: 35px;
}

.queueTab {
  margin-top: 29px;
  padding-right: 30px;
}

.reportsTab {
  margin-top: 36px;
}

.reportsInputField {
  input {
    height: 40px;
    margin-top: 3px;
  }
}

.datepickerField {
  margin-top: 3px !important;
}
.selectField {
  height: 40px;
  box-sizing: border-box;
  box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));
  border-radius: 15px !important;
  border: none !important;
  margin-top: 1px;

  @media screen and (max-width: 550px) {
    max-width: 100%;
  }
}

.inputLabel {
  font-size: 14px;
  font-weight: 500;
}

.masTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}

.paymentInputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 auto;

  .dividerContainer {
    display: flex;
    justify-content: center;

    .divider {
      width: 100%;
      max-width: 900px;
    }
  }

  .paymentAccounts {
    display: flex;
    width: 100%;
    gap: 50px;
    height: 352px;

    .paymentInputSelect {
      width: 50%;
    }
  }

  .label {
    font-weight: 500;
    font-size: 16px;
    color: #111326;
    line-height: 19.36px;
  }

  .error {
    color: $error-red;
    margin-top: 12px;
  }

  .pageActions {
    display: flex;
    gap: 14px;
    justify-content: center;
    padding: 24px;
  }
}

.list {
  .table {
    @include mixins.marginVertical(40px);

    th {
      color: $darkest-blue;
      font-weight: 700 !important;
      @include mixins.font(22px, 14px);

      &:first-child {
        max-width: 150px;
      }
    }

    tbody {
      tr td {
        border-bottom: none !important;

        .name {
          display: flex;

          svg {
            margin-top: 6px;
            margin-right: 20px;
          }
        }

        b {
          font-weight: 600;
        }

        p {
          color: $darkest-blue;
          max-width: 200px;
          @include mixins.font(24px, 18px);
        }

        .view {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.reportsContainer {
  padding: 0;
  padding-right: 40px;
  flex-direction: column !important;

  .loading {
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 80px;
    display: flex;
    align-items: center;
  }

  .noData {
    color: $dark-grey;
    font-weight: 500;
    margin: 100px auto
  }
}

