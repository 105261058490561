$white: #FFFFFF;
$darkest-blue: #111326;
$night-blue: #191C49;
$merge-blue: #203F7C;
$dark-turquoise: #016C87;
$merge-turquoise: #00A09B;
$light-green: #57AD9E;
$error-red: #FF8888;
$dark-grey: #777777;
$light-grey: #C4C4C4;
$lightest-grey: #E5E5E5;
$sandbox-orange: #E04E10;
$warning-red: #FF3131;
$button-gradient: linear-gradient(270deg, #00A09B 0%, #016C87 100%);
$semi-transparent:  rgba(255, 255, 255, 0.5);
$merge-turquoise-light: rgb(0, 160, 155, 0.3);


$auth-width: 616px;
$font: Inter;

$small-width: 320px;
$medium-width: 834px;
$large-width: 1440px;
$extra-width: 1920px;
