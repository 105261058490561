@use "src/assets/styles/common/variables.scss" as *;
@use "src/assets/styles/common/mixins.scss" as mixins;
@use "src/assets/styles/common/global.scss" as *;
@use "src/assets/styles/common/functions.scss" as *;

.container {
    padding: 17px 31px 0px;
    @media screen and (max-width: 520px) {padding-left: 18px; padding-right: 18px !important}
    @media screen and (max-width: 899px) {padding-top: 25px; padding-right: 31px}
    font-family: Inter , 'Open Sans', sans-serif;
  
    .title {
      color: $darkest-blue;
      @include mixins.font(36px, 32px);
      @media screen and (max-width: 460px){@include mixins.font(24px, 20px);}
    }
  
    .title {
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.25rem;
    }
    .description {
        color: $darkest-blue;
        margin-top: 56px;
        font-weight: 400;
        font-size: 16px;
        text-align: justify;
        line-height: 27px;
    }
    .apiKey {
      .noData {
          display: flex;
          justify-content: space-between;
          color: $dark-grey;
          font-weight: 500;
          font-size: 16px;
          line-height: 27px;
          svg {
            padding: 7px;
            background-color: rgba(229, 229, 229, 0.3);
            border-radius: 40%;
          }
          span {
            margin-top: 4px;
            margin-left: 10px;
            color: rgba(82, 82, 82, 1);
          }
      }
      .data {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        .datacol {
          display: flex;
          flex-direction: column;
          margin-right: 60px;
          gap: 10px;
          .header {
            color: $dark-grey;
            line-height: 20px;
          }
          .value {
            line-height: 20px;
          }
          .deactivateBtn {
            background: #EB6161;
            border: 1px solid #EB6161;
            border-radius: 15px;
            gap: 10px;
            font-weight: 400;
            width: 180px;
            font-size: 18px;
            line-height: 26px;
            cursor: pointer;
            margin-left: auto;
          }
        }
        span {
            font-weight: 400;
            font-size: 20px;
        }
      }
    }
    .generateKey {
      .createKeyBtn {
        border-radius: 15px;
        font-weight: 400;
        padding: 5px 15px;
        padding: 10px 20px;
        font-size: 18px;
        line-height: 26px;
        cursor: pointer;
      }
    }
    .divider {
      margin: 30px 0px;
    }
}

.apiKeyModal {
  .closeIcon {
    position: absolute;
    right: 35px;
    top: 25px
  }
}

.dialog {
  text-align: center;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #111326;
    padding: 0 70px;
    
    @media (max-width: 992px) {
      padding: 0 20px;
    }
  }
  .newKey {
    width: 90%;
    margin: 20px 5%;
    .title{
      font-weight: 500;
      font-size: 24px;
      line-height: 38px;  
    }
    .info {
      margin-top: 25px;
      margin-bottom: 10px;
      text-align: center;
      line-height: 1.5;
    }
    .warningInfo {
      padding: 3px;
      padding: 5px 20px;
      font-size: 13px;
      color: #FF3131;
    }
    .keyHolder {
      margin-top: 30px;
      padding: 10px 5px;
      background: #e5e5e5;
      border-radius: 10px;
      display: flex;
      justify-content: flex-end;
      .key {
        margin-left: 20px;
        font-size: 14px;
        margin-right: auto;
      }
      .showKey {
        margin-right: 20px;
      }
      .icon {
        margin-right: 20px;
      }
    }
    .actionBtn {
      margin-top: 32px;
      margin-bottom: 32px;
      @include mixins.submitButton($merge-turquoise, $white);
      @media (max-width: ($small-width + 280px)) {
          padding: 11px;
      }
      font-size: 18px;
      width: 95%;
    }
  }
}

.deactivateDialog {
  text-align: center;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: $darkest-blue;
    padding: 0 70px;
    
    @media (max-width: 992px) {
      padding: 0 20px;
    }
  }
  .confirmation {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    padding: 10px 30px 0px 30px;
  }
}
