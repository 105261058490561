* {
  margin: 0;
  padding: 0;
}

button, svg {
  cursor: pointer;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  margin-top: 18px;
  //border: 1px solid #C4C4C4;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 15px;
  max-height: 245px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  margin-top: 8px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  //max-height: 360px;
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeSmall {
    position: absolute;
    right: 50px;
  }
  @media screen  and (max-width: 480px){
    min-width: calc(100% - 32px) !important;
  }
}

.MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: rgba(0, 160, 155, 0.19) !important;
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
.PrivatePickersYear-yearButton.Mui-selected {
  background-color: #00A09B !important;
}

.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
  border: 1px solid #00A09B !important;
}

#demo-controlled-open-select {
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 0;
}

#demo-controlled-open-select[aria-expanded="false"] {
  p {
    white-space: unset !important;
    overflow: hidden;
  }
}

//.veriff-submit {
//  width: 480px !important;
//  height: 56px !important;
//  background: linear-gradient(270deg, #00A09B 0%, #016C87 100%);
//  border-radius: 15px !important;
//  text-transform: capitalize !important;
//  font: 20px Inter !important;
//  @media screen  and (max-width: 600px){
//    height: 48px !important;
//    width: 100% !important;
//  }
//}
//.disabled {
//  .veriff-submit {
//    background: #C4C4C4 !important;
//    color: #fff !important;
//    pointer-events: none;
//  }
//}

//.veriff-description {
//  margin-top: 32px !important;
//  text-align: left !important;
//  font: 14px Inter !important;
//  font-style: normal;
//  font-weight: normal !important;
//  color: #777777 !important;
//  >a {
//    color: #00a09b !important;
//    font: 14px Inter !important;
//    text-decoration: none;
//  }
//}

.MuiSelect-select p {
  text-align: left !important;
}

.MuiAutocomplete-endAdornment {
  button {
    &:nth-child(2) {
      display: none;
    }
  }
}
.MuiAutocomplete-inputRoot {
  padding-right: 39px !important;
}

@media screen and (max-width:600px ){ // should refactor this file
  .MuiAutocomplete-inputRoot {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #demo-controlled-open-select {
    padding: 8px;
  }
}

.pie-chart .recharts-wrapper {
  @media screen and (min-width: 834px) and (max-width: 1260px){
    width: 0 !important;
    padding-left: 10%;
  }
}
//.line-chart {
//  margin-left: -20px;
//}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//#veriff-vendor-data,
//#veriff-given-name,
//#veriff-last-name {
//  opacity: 0;
//}

.Disabled {
  span, p {
    color: #777777;
  }
}


::placeholder {
  font-weight: 300 !important
}

:-ms-input-placeholder {
  font-weight: 300 !important
}

::-ms-input-placeholder {
  font-weight: 300 !important
}

#outlined-basic_phone:-webkit-autofill,
#outlined-basic_phone:-webkit-autofill:hover,
#outlined-basic_phone:-webkit-autofill:focus,
#outlined-basic_phone:-webkit-autofill:active  {
  transition: background-color 5000s ease-in-out 0s;
}
