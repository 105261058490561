@use "src/assets/styles/common/mixins.scss" as mixins;
//@import "src/assets/styles/global.module";
@import "src/assets/styles/common/functions";
@import "src/assets/styles/common/variables";

* {
  font-family: Inter !important;
}
.filter {
  display: flex;
  align-items: flex-end;
  //flex-direction: row;
  @media screen and (max-width: 599px) {
    flex-direction: column-reverse !important;
  }
}

.avatarSubIcon {
  position: absolute;
  bottom: -5px;
  right: 14px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  padding-top: rem(17);
  padding-left: rem(31);
  padding-right: rem(31);
  @media screen and (max-width: 899px) {
    padding-top: rem(25);
  }
  @media screen and (max-width: 600px) {
    padding-left: rem(16);
    padding-right: rem(16);
  }
  .titleWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    .title {
      margin: 0;
      font-weight: normal;
      @include mixins.font(36px, 32px);
      color: $darkest-blue;
      @media screen and (max-width: 600px) {
        font-size: 20px ;
        margin-bottom: 16px;
        @include mixins.font(24px, 20px);
      }
    }
  }
}

.action-wrapper {
  display: flex;
  margin-left: auto !important;
  padding-left: 10px;
  gap: 10px;
}

.button-wrapper {
  margin-bottom: 0px;
}

.add {
  width: 211px;
  @media screen and (max-width: 600px) {  width: 100%;}
}

.bankInfo {
  margin-top: 20px;
  font-size: rem(16);
  line-height: rem(20);
  color: $darkest-blue;
  word-break: break-all;
}

.inputField {
  width: 100%;
}

.search-recipient {
  .inputField {
    margin-top: 12px;
  }
  max-width: 507px;
  @media screen and (max-width: 600px) {min-width: 100%;}
  input {
    height: 40px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: rgb(247, 248, 252);
  border: 1px solid #ccc;
  margin-top: 10px;
  border-radius: 15px;
  padding: 12px;
  .segment {
    border-bottom: 1px solid rgb(229, 229, 229);  
    width: 98%;
    padding: 0px 12px;
    display: flex;
    &:last-child {
      border-bottom: 0px;
    }  
  }
}

.details {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  .detailsColumn {
    display: flex;
    flex-direction: column;
  }
  .deleteIcon {
    fill: #EB6161;
    svg{
      fill: #EB6161;
    }
  }
  .deleteBtn {
    width: 205px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
  }
  .link {
    color: $merge-turquoise;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 16px;
  }
}

.action {
  width: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.collapsible {
  margin-bottom: 40px;
  .itemHeader {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    .date {
      display: flex;
      .label {
        font-size: 12px;
        font-weight: 400;
        line-height: 28px;    
      }
      .seperator {
        font-size: 12px;
        font-weight: 700;
        line-height: 28px;
        margin: 0px 10px;
        color: rgba(153, 153, 153, 1);
      }
      .value {
        margin-left: 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
      }  
    }  
  }
}

.data, .data30, .data35 {
  width: 46%;
  margin: 12px 0px;
  margin-right: 16px;

  &30 { // overriding width for .data30
    width: 30%;
  }
  &35 {
    width: 35%;
  }
  .label {
    color: #666;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    line-height: 20px;
  }
  .value {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    line-height: 28px;
  }
  .dataBlock {
    display: flex;
    align-items: flex-start;
    img {
      margin-top: 5px;
    }
  }
} 

.editDetails {
  display: flex;
  align-items: center;
  color: #1C96E4;
  margin-bottom: 1px ;
  text-decoration: underline;
  cursor: pointer;

  svg {
    fill: #1C96E4;
    margin-left: 10px;
    path {
      fill: #1C96E4;
    }
  }
}

.identifer {
  padding-right: 10px;
}

.row{
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  .paymentPurposeAlignment {
    box-sizing: border-box;
    padding-right: 22px !important;
  }
  .record {
    display: flex;
    padding-top: 10px;
    padding-right: 24px;
    .label {
      color: #666;
      width: 200px;
      font-size: 14px;
      font-weight: 700;
      height: 20px;
      line-height: 20px;
    }
  }
  .w50 {
    width: 50%;
  }
  .w100 {
    width: 100%;
  }
  .w33 {
    width: 33%;
  }
}


.sectionTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  margin-top: 21.5px;
  margin-bottom: 16px;
}

.asterisk {
  color: $error-red;
  margin-left: 4px;
}

.divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(229, 229, 229);
  padding: 0;  
  margin-top: 32px;
  margin-bottom: 32px;
}

.recipients {
  .title {
    font-size: rem(24);
    font-weight: normal;
    color: $dark-grey;
    @media screen and (max-width: 600px) { font-size: 20px !important}
  }

  .dialog-title {
    text-align: center;
    font-size: rem(24);
  }

  .list {
    margin-top: rem(20);
    margin-bottom: rem(30);

    .empty {
      margin-top: rem(20);
      font-size: rem(16);
      color: $dark-grey;
      font-weight: normal;
      text-align: center;
      font-family: Inter;

      &.error {
        color: $error-red;
      }

      p {
        margin-bottom: rem(10);
      }
    }

    .item {
      border-top: 1px solid rgb(229, 229, 229);

      .avatar {
        margin-right: 20px;
        width: 60px;
        height: 45px;
        background: $merge-turquoise-light;
        color: $merge-turquoise;
        border-radius: 8px;
        font-size: 16px;
      }

      &:last-child {
        border-bottom: 1px solid rgb(229, 229, 229);
      }

      .action {
        margin: 0 rem(20.5);

        svg {
          vertical-align: middle;
          fill: $merge-turquoise;
          path {
            fill: $merge-turquoise;
          }
        }
        &[disabled] {
          pointer-events: none;
          svg {
            vertical-align: middle;
            fill: $light-grey;
            path {
              fill: $light-grey;
            }
          }
        }
      }
    }
  }

  .pagination {
    .select-item {
      font-size: rem(14);
      float: right;
    }

    .pagination-actions {
      span {
        font-size: rem(14);
        margin: rem(0) rem(20);
        vertical-align: text-bottom;
      }
    }
  }
}

.add-recipients {
  padding: 36px 12px 0px;
}

.submitButton {
  width: 180px;
  margin-right: 20px;
}

.recipients-add {
  padding: 0px 10px;
  .error {
    color: $error-red;
    font-size: rem(14);
    margin-bottom: rem(10);
  }
  .outlined-button {
    border: 1px solid $merge-turquoise;
    box-sizing: border-box;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
    border-radius: 15px;
    color: $merge-turquoise;
    padding: rem(7) rem(40);

    &:hover {
      background: $button-gradient;
      color: $white;
      border: inherit;
    }
  }
}

.modal-content {
  text-align: center;
  padding: 0  rem(30);
  @media screen  and (max-width: 480px){
    padding: 0;
  }
  svg {
    max-width: 100%;
    width: 316px;
    height: fit-content;
  }
}

.MuiBackdrop-root {
  background-color: rgba(255,255,255, 0.8);
}

.MuiTooltip-popper {
  background: #fff;
}

.buttonGroups {
  display: flex;
  justify-content: center;
  >div {
    width: 100%;
    padding: 10px 20px;
    &:first-child {
      margin-right: 8px;
    }
    &:nth-child(2) {
      margin-left: 8px;
    }
  }
}

.recipientsLoading {
  position:fixed;
  top:0;
  left: 0;
  height: 100%;
  margin-left: 260px;
  opacity: 0.7;
  z-index: 10000;
  width: 650px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 80px;
    width: 80px;
  }

  //@media screen and (min-width: $medium-width - 34px) and (max-width: $medium-width) {
  //  width: calc(100vw - 240px);
  //}

  @media screen and (max-width: 900px) {
    margin-left: 0;
    width: 100%
  }
}

.modalLoading {
  position:fixed;
  top:0;
  left: 0;
  height: 100%;
  opacity: 0.7;
  z-index: 10000;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 80px;
    width: 80px;
  }

  //@media screen and (min-width: $medium-width - 34px) and (max-width: $medium-width) {
  //  width: calc(100vw - 240px);
  //}

  @media screen and (max-width: 900px) {
    margin-left: 0;
    width: 100%
  }
}


.stepper-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  color: $dark-grey;
  font-size: rem(24);
  line-height: rem(30);
  @media screen and (max-width: 899px) {
    font-size: rem(20);
    line-height: rem(24);
  }
}

.stepper-wrapper {
  // margin: rem(6) 0;
  margin-bottom: 37.5px;

  .stepper-connector {
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      position: absolute;
      top: 12px;
      left: calc(-50% + 20px);
      right: calc(50% + 20px);

    span {
       display: block;
       border-color: #bdbdbd;
       border-top-style: dashed;
       border-top-width: 1px;
    }
  }
  .Mui-completed {
    &.MuiStepLabel-label {
      color: $merge-turquoise;
    }
  }
}
