@use "src/assets/styles/common/variables.scss" as *;
@use "src/assets/styles/common/mixins.scss" as mixins;
@use "src/assets/styles/common/global.scss" as *;
@use "src/assets/styles/common/functions.scss" as *;

.container {
  padding: 17px 31px;

  @media screen and (max-width: 520px) {
    padding-left: 18px;
    padding-right: 18px !important
  }

  @media screen and (max-width: 899px) {
    padding-top: 25px;
    padding-right: 31px
  }

  .currencyDetails {
    width: 100%;
    border: 1px solid #eee;
    border-radius: 15px;
    padding: 30px 30px 20px 30px;
    margin: 20px 0px 0px 0px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    color: #111326;

    .currencyHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .currencyTitle {
        display: flex;
        align-items: center;
      }
      .cumulativeBalance{
        display: flex;
        align-items: center;
        .viewDetails {
          color: #00A09B;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }

    .accountName {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 24px;
      margin-left: -14px; // To compensate info tooltip
    }

    .totalBalance {
      font-weight: 600;
      font-size: 24px;
      line-height: 38px;
      margin-right: 190px;
    }

    .snapShot {
      margin: 25px 0px 10px 0px;
      
      .detail {
        // &:nth-of-type(odd) {
        //   background-color: rgba(0,160,155,0.05);
        // }
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        padding: 10px 0px;
        border-bottom: 1px solid rgb(229, 229, 229);
        &:last-child{
          border-bottom: 0px;          
        }

        .summaryBalance {
          display: flex;
          .accBalance {
            color: #525252;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            margin-right: 30px;
          }
        }

        .summaryPanel {
          display: flex;
          gap: 17px;
  
          .avatar {
            width: 30px;
            height: 30px;
            background: rgba(0, 160, 155, 0.19);
            color: $merge-turquoise;
            font-size: 11px;
        
            &Disable {
              color: #777777;
            }
          }          
          .clientName {
            font-weight: 400;
            font-size: 16px;
          }
          .bankDetails {
            color: #525252;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
          }
          .tagContainer {
            height: 30px;
            display: flex;
            align-items: center;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          span, button {
            @include mixins.font(22px, 18px);
          }
          &Icon {
            @include mixins.iconStyles($merge-turquoise);
          }
          &IconSandbox {
            @include mixins.iconStyles($sandbox-orange);
          }
          &IconWarning {
            @include mixins.iconStyles($warning-red);
          }
        }

        .accountBalance {
          font-weight: 600;

          @media screen and (max-width: 880px) and (min-width: 541px) {
            left: 98px;
            top: 70px !important
          }

          @media screen and (max-width: 540px) {
            top: 100px !important;
            left: 98px;
          }

          span {
            font-weight: 600 !important;
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .subFilter {
    position: relative;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 820px) {
      flex-direction: column;
      width: 100%;
    }

    .actions {
      margin-top: 40px;
      display: flex;
      gap: 20px;
      margin-left: auto;
    }
  }

  .status {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 820px) {
      width: 75%;
      margin-right: 20px;
    }
  }

  .searchButton {
    height: 40px;
    padding: 7px 54px;
    width: 166px;
    margin: 0px 5px;
    @include mixins.font(26px, 18px);

    @media screen and (max-width: 420px) {
      padding: 7px 18px;
    }
  }
}

.searchField {
  margin-top: 12px;
  max-width: 216px;
  box-sizing: border-box;
  border-radius: 15px;
  border: none !important;
  box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));

  >div {
    max-height: 0 !important;
    padding: 20px 0;

    >div {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 550px) {
    max-width: 100%;
  }
}

.statusField {
  @extend .searchField;
  margin-top: 0;
  margin-right: 32px;
  min-width: 216px;
  max-width: 216px;

  @media screen and (min-width: 820px) and (max-width: 879px) {
    min-width: 200px;
    max-width: 200px;
  }

  @media screen and (max-width: 820px) {
    min-width: 100%;
  }
}

.search-accounts {
  min-width: 480px;
  margin-right: 32px;

  input {
    height: 40px;
  }

  @media screen and (max-width: 980px) and (min-width: 881px) {
    min-width: 370px;
  }

  @media screen and (max-width: 880px) and (max-width: 821px) {
    min-width: 340px;
  }

  @media screen and (max-width: 820px) {
    margin-top: 0 !important;
    margin-right: 0;
    min-width: unset;
  }
}

.list {
  margin-top: 15px;

  @media screen and (max-width: 899px) {
    margin-top: 56px
  }

  .subItem {
    height: 100%;
    min-height: 84px;

    @media screen and (max-width: 880px) {
      height: 112px;

      div:nth-child(2) {
        margin-bottom: 40px;
      }
    }

    @media screen and (max-width: 540px) {
      height: 140px;

      div:nth-child(2) {
        margin-bottom: 18px;
      }
    }

    @media screen and (max-width: 440px) {
      height: 150px;

      div:nth-child(2) {
        margin-bottom: 36px;
      }
    }
  }

  .accountBalance {
    margin-right: 30px;
    font-weight: 600;

    span {
      font-weight: 600 !important;
      font-size: 14px !important;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    span,
    button {
      @include mixins.font(22px, 18px);
    }

    &Icon {
      @include mixins.iconStyles($merge-turquoise);
    }

    &IconSandbox {
      @include mixins.iconStyles($sandbox-orange);
    }

    &IconWarning {
      @include mixins.iconStyles($warning-red);
    }
  }
}

.item {
  height: 96px;
  border-top: 1px solid $lightest-grey;
  font-size: 14px;

  @media screen and (max-width: 580px) {
    height: 127px;
  }

  p {
    @media screen and (max-width: 540px) {
      max-width: 160px;
      margin-bottom: 18px;
    }
  }

  .avatar {
    width: 47px;
    height: 47px;
    background: rgba(0, 160, 155, 0.19);
    color: $merge-turquoise;
    margin-right: 17px;
    font-size: 16px;

    &Disable {
      color: #777777;
    }
  }

  &:last-child {
    border-bottom: 1px solid $lightest-grey;
  }
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 50%;
  background: rgba(255, 255, 255, 0.5);
}

.loading {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  margin-top: 80px;
  display: flex;
  align-items: center;
}

.error {
  color: $error-red;
  font-size: rem(14);
  margin-bottom: rem(10);
}

.empty {
  margin-top: rem(50);
  width: 100%;
  font-size: rem(16);
  color: $dark-grey;
  font-weight: normal;
  text-align: center;
  font-family: Inter;

  &.error {
    color: $error-red;
  }
}

.noData {
  color: $dark-grey;
  font-weight: 500;
  margin: 100px auto;
}

.accountInfo {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .balance {
    width: 60%;
  
    @media screen and (max-width: 484px) {
      margin-top: 40px;
    }
  
    .refresh {
      display: flex;
      align-items: center;
  
      >h3 {
        color: $dark-grey;
        @include mixins.font(29px, 24px);
        @include mixins.fontWeight(500);
      }
  
      svg {
        margin-left: 8px;
        margin-top: 4px;
      }
    }
  
  
    >h2 {
      margin-top: 16px;
      color: $darkest-blue;
      @include mixins.font(39px, 32px);
      @include mixins.fontWeight(600);
    }
  
    >h5 {
      color: $dark-grey;
      margin-top: 16px;
      @include mixins.font(19px, 16px);
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }  

  .copy {
    position: relative;
    width: 40%;

    max-width: fit-content;
  
    @media screen and (max-width: 484px) {
      margin-top: 40px;
    }
  
    >p {
      color: $darkest-blue;
      margin-bottom: 8px;
      @include mixins.font(24px, 18px);
  
      @media screen and (max-width: 340px) {
        font-size: 16px
      }
  
      >span {
        margin-right: 6px;
      }
  
      &:first-child {
        display: flex;
        align-items: center;
      }
    }
  
    .copied {
      color: #00A09B;
    }
  }
  
}

.fundsButton {
  width: 166px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  .title {
    color: $night-blue;
    @include mixins.font(36px, 32px);
  
    @media screen and (max-width: 460px) {
      @include mixins.font(24px, 20px);
    }
  }
  
}

.form {
  position: relative;
  text-align: center;
  &Radio{
    text-align: left;
  }

  &Loading {
    @extend .full;
    @extend .center;
    position: absolute;
    background: $semi-transparent;
    z-index: 190;
  }

  .messageText {
    margin-top: rem(16);
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;

    p {
      margin-top: rem(16);
    }

  &.error {
    color: $error-red;
  }
  }
}

.action-wrapper {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;
}

.box {
  height: 50%;

  p {
    text-align: center;
    padding-top: 80px;
  }
}

.typeTag {
  line-height: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  // border: 1px solid #13194A;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 160, 155, 0.05);
  border-radius: 10px;
  padding: 1px 10px;
}

.avatarSubIcon {
  position: absolute;
  bottom: -5px;
  right: 10px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarSubIconSummary {
  position: absolute;
  bottom: -3px;
  right: -5px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.bankAccountInfo {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #00A09B;
  .tos {
    margin-top: 10px;
    font-size: 12px;
    color: #777777;
    a {
      color: #777777;
    }
  }
}

.centerLoader {
  left: 50%;
  transform: translateX(-50%);
}

.ctaBox {
	width: 24px;
	height: 24px;
	margin-left: 14px
  }
  
  
  .cta {
	display: flex;
	flex-direction: column;
	gap: 20px;
  
	span, button {
	  @include mixins.font(22px, 18px);
	}
	&Icon {
	  @include mixins.iconStyles($merge-turquoise);
	}
	&IconSandbox {
	  @include mixins.iconStyles($sandbox-orange);
	}
  }
  
  .kebabIcon {
	border-radius: 8px;
	width: 24px;
	height: 24px;
	padding: 1px 0;
	transition: background-color 0.1s ease-out;
  
	&Hilight {
	  background-color: rgba(0, 160, 155, 0.19);
	}
  }
  