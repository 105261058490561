@use "common/variables" as *;
@use "common/global" as *;
@use "common/mixins" as mixins;

$formWith: 480px;

.auth {
  position: relative;
  width: $auth-width;
  border-radius: 30px;
  z-index: 200;
  margin: 0 auto;
  @extend .gray-shadow;
  @media screen and (min-width: $medium-width - 134px) and (max-width: $medium-width + 190px) {
    margin: 66px auto;
    max-width: 616px;
  }
  @media screen and (min-width: $small-width + 181px) and (max-width:  $medium-width - 135px) {
    margin: 40px auto;
    width: 88%;
  }
  @media screen and (min-width: $small-width) and (max-width:   $small-width + 180px) {
    margin: 40px auto;
    width: 92%;
  }

  .authLoading {
    @extend .full;
    @extend .center;
    position: absolute;
    background: $semi-transparent;
    z-index: 190;
  }

  .authForm {
    margin: 40px 68px;
    @media (min-width:#{$small-width + 151px}) and (max-width:#{$medium-width - 134px})  {margin: 40px;}
    @media (max-width:#{$small-width + 151px})  {margin: 40px 16px}
    .paragraph {
      margin-top: 24px;
      @include mixins.font(26px, 18px);
      @media (max-width:#{$small-width + 280px}) {  @include mixins.font(20px, 16px); margin-top: 16px}
    }

    .form {
      margin-top: 32px;
      margin-bottom: 24px;
      width: $formWith;
      @media (max-width:#{$medium-width - 134px})  {
        width: auto;
        margin-top: 25px;
        margin-bottom: 16px;
      }
      .existsUser {
        color: $darkest-blue;
        margin-bottom: 5px;
        margin-top: 8px;
        @include mixins.font(18px, 16px);
        @media (max-width:#{$small-width + 280px}) {  @include mixins.font(18px, 14px);}

        >span {
          cursor: pointer;
          @extend .transparent;
          color: $merge-turquoise;
          @include mixins.font(16px, 16px);
          @media (max-width:#{$small-width + 280px}) {  @include mixins.font(18px, 14px)}
        }
      }
    }
    .passInfo {
      color: $darkest-blue;
      margin-top: 8px;
      @include mixins.font(20px, 16px);
      @media (max-width: $small-width + 280px){
        @include mixins.font(16px, 14px);
      }
    }

    .haveAccount {
      text-align: center;
      color: $darkest-blue;
      margin-bottom: 16px;
      @include mixins.font(24px, 20px);
      @media (max-width: $small-width + 280px){
        @include mixins.font(16px, 16px);
        margin-bottom: 8px;
      }

      >button {
        color: $merge-turquoise;
        @extend .transparent;
        @include mixins.font(20px, 20px);
        //@media (min-width: $medium-width + 201px ){margin-bottom: 16px}
        @media (max-width:$small-width + 280px) {  @include mixins.font(20px, 16px); }
      }
    }
  }
}

.submitBtn {
  margin-top: 32px;
  @include mixins.submitButton($merge-turquoise, $white);
  @media (max-width: $small-width + 280px) { padding: 11px}
}

.title {
  @include mixins.font(39px, 32px);
  @include mixins.fontWeight(normal);
  color: $darkest-blue;
  text-align: center;
  @media (max-width: $small-width + 280px) { @include mixins.font(24px, 20px)}
}