@use "common/variables" as *;
@use "common/global" as *;
@use "common/mixins" as mixins;
@use "common/functions" as *;

.inputField {
  >div {
    padding: 0;
  }

  input {
    height: 56px;
    border-radius: 15px !important;
    @include mixins.input($light-grey);

    @media (max-width:#{$small-width + 280px}) {
      height: 41px
    }

    &:focus {
      @include mixins.inputFocus($dark-turquoise)
    }
  }

  .MuiInputBase-formControl {
    padding-right: 0;
  }
}

.inputFieldV2 {
  height: 66px;

  .inputContainer {
    display: flex;
    align-items: center;
    border: 1px solid #C4C4C4;
    border-radius: 8px;

    .inputLabel {
      display: flex;
      padding: 0px 15px;
      width: fit-content;
      font-size: 14px;
      font-weight: 500;
      background: rgba(238, 238, 238, 0.3);
      height: 66px;
      align-items: center;
    }

    .input {
      border-left: 0.5px solid #C4C4C4;
      flex: 1;
    }
  }
}

.rangePickerV2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  // height: 120px;
  background-color: rgba(247, 248, 252, 1);
  gap: 16px;
  border-radius: 12px;
  padding: 24px 16px;

  .datePickerContainer {
    display: flex;
    align-items: center;
    background-color: rgba(251, 251, 251, 1);
    border: 0.5px solid rgba(102, 102, 102, 1);
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    .datePicker {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      color: rgba(17, 19, 38, 1);
      box-sizing: border-box;
      padding: 0px 16px;
      height: 68px;

      .calendarIcon {
        width: 20px;
        height: 20px;
        cursor: auto;
      }

      .dateValue {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        padding-left: 10px;
      }
    }

    .dropDown {
      width: 16px;
      height: 11px;
      margin-left: auto;
      margin-right: 28.5px;

      path {
        fill: rgba(47, 43, 61, 1);
      }
    }
  }
}

.password {
  input {
    padding-right: 45px;
  }
}

fieldset {
  border: none !important
}

.adornment {
  position: absolute;
  right: 15px;
}

.start-adornment {
  position: absolute;
  left: 15px;
}

.end-adornment {
  padding: 0 !important;
  position: absolute;
  right: 12px;
  top: 8px;
}

.icon-start {
  input {
    padding-left: rem(40);
  }
}

.otpLoading {
  position: absolute;
  z-index: 190;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  padding-top: 40px;
}

.label {
  color: $darkest-blue;
  margin-bottom: 5px;
  margin-top: 16px;
  @extend .align-items-center;
  @include mixins.font(19px, 16px);

  @media (max-width:#{$small-width + 280px}) {
    margin-bottom: 11px
  }
}

.rangeLabel {
  @extend .align-items-center;
  margin-top: 16px;
  margin-left: 8px;

  p {
    @include mixins.font(17px, 14px);
    @include mixins.fontWeight(500);
    color: #333333;
    margin-bottom: 8px;

    &:nth-child(2) {
      margin-left: 80px;
    }
  }
}

.disable {
  margin-bottom: 5px;
  margin-top: 16px;
  color: $light-grey;
  @include mixins.font(19px, 16px);
  @extend .align-items-center;
}

.error {
  color: $error-red;
  align-items: flex-start;
  font-size: 14px;

  input {
    @include mixins.border($error-red);
    color: inherit;
  }

  p {
    margin-top: 8px;
    margin-left: 0;
    color: $error-red !important;
    @include mixins.font(18px, 14px);
  }
}

.asterisk {
  color: $error-red;
  margin-left: 8px;
}

.info {
  display: flex;
  margin-left: 14px;
}

.tooltipComponent {
  position: relative;
  border-radius: 5px;
  z-index: 1;
  @extend .center;

  >p {
    @include mixins.paddingVertical(4px);
    @include mixins.font(14px, 10px);
    text-indent: 5px;
    color: #000000;
  }

  svg {
    z-index: 100;
    position: absolute;
    top: 24px !important;
  }
}

.focus {
  border-color: $dark-turquoise !important;
  box-shadow: 0 0 5px $dark-turquoise !important;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  // max-height: 56px;
}

.selectField {
  box-sizing: border-box;
  width: 100%;
  border-radius: 15px;
  height: 56px;

  @media screen and (max-width: ($small-width + 280px)) {
    height: 41px;
  }
}

.autocompleteFieldOverride {
  opacity: 1;
  margin-top: 12px;
  box-sizing: border-box;
  border-radius: 15px;
  border: none !important;
  box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));
}

.paymentSearchField {
  margin-top: 12px;
  max-width: 250px;
  box-sizing: border-box;
  border-radius: 15px;
  border: none !important;
  box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));

  >div {
    max-height: 0 !important;
    padding: 20px 0;

    >div {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 550px) {
    max-width: 100%;
  }

  .indeterminateColor {
    color: #f50057;
  }
}

.accountsSearchField {
  margin-top: 12px;
  width: 350px;
  box-sizing: border-box;
  border-radius: 15px;
  border: none !important;
  box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));

  >div {
    max-height: 0 !important;
    padding: 20px 0;

    >div {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 550px) {
    max-width: 100%;
  }

  .indeterminateColor {
    color: #f50057;
  }
}

.searchField {
  margin-top: 12px;
  max-width: 216px;
  box-sizing: border-box;
  border-radius: 15px;
  border: none !important;
  box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));

  >div {
    max-height: 0 !important;
    padding: 20px 0;

    >div {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 550px) {
    max-width: 100%;
  }
}

.statusField {
  @extend .searchField;
  margin-top: 0;
  margin-right: 32px;
  min-width: 216px;
  max-width: 216px;

  @media screen and (min-width: 820px) and (max-width: 879px) {
    min-width: 200px;
    max-width: 200px;
  }

  @media screen and (max-width: 820px) {
    min-width: 100%;
  }
}

.default {
  border: 1px solid $light-grey ;
  border-radius: 15px;
}

.errorBorder {
  border: 1px solid $error-red !important;

  p {
    margin-top: 8px;
    margin-left: 0;
    color: $error-red !important;
    @include mixins.font(18px, 14px);
  }
}

.flag {
  height: 14px;
  width: 20px !important;
  margin-right: 8px;
  border-radius: 2px;
  object-fit: cover;
}

.itemText {
  display: inline-block;
}

.multiSelectMenuItem {
  background-color: transparent !important;

  &:hover {
    background-color: rgba(0, 160, 155, 0.1) !important;
  }

  >p {
    @include mixins.font(32px, 16px);
    color: $darkest-blue;
  }

  .checkmark {
    position: absolute;
    right: 19px;
  }
}


.selectMenuItem {
  padding: 8px !important;
  background-color: transparent !important;

  &:hover {
    background-color: rgba(0, 160, 155, 0.1) !important;
  }

  >p {
    @include mixins.font(32px, 16px);
    color: $darkest-blue;
  }

  .checkmark {
    position: absolute;
    left: 12px;
  }
}

.listMenuItem {
  white-space: break-spaces;
  margin-left: 30px;
}

.selectMenuItemActive {
  background-color: rgba(0, 160, 155, 0.1) !important;
}

.divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid $light-grey;
}

.paymentDivider {
  width: 50%;
  height: 1px;
  border-top: 1px solid #e5e5e5;
  margin: 50px 0px 15px;
  margin-left: 25%;
}

.invalid {
  >div {
    border: 1px solid $error-red !important;
    color: $error-red;
  }

  p {
    margin-top: 8px;
    margin-left: 0;
    color: $error-red !important;
    @include mixins.font(18px, 14px);
  }
}

.pickerField {
  width: 100%;

  >div {
    border: 1px solid #C4C4C4;
    border-radius: 15px;

    @media screen and (max-width: ($small-width + 280px)) {
      height: 41px;
    }
  }
}

.fromDate,
.toDate {

  // width: 50%;
  p {
    margin: 16px 0 6px 4px
  }

  >div {
    max-width: 146px;

    @media screen and (max-width: 550px) {
      min-width: 100%;
    }
  }
}

.toDateWrapper {
  p {
    margin: 16px 0 5px;
  }

  >div {
    width: 100%;
  }
}

.fromDate {
  margin-right: 15px;

  @media screen and (max-width: 550px) {
    margin-right: 0;
  }
}

.pickerRange {
  min-width: 146px;
  margin-top: 6px !important;

  >div {
    border-radius: 15px;
    height: 40px;
    padding-left: 40px;
    box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));

    input {
      @include mixins.paddingVertical(7.5px);
      font-size: 14px;
    }
  }
}

.closeIcon {
  position: absolute;
  right: 29px;
  top: 15px
}

.actionGroup {
  margin: 4px auto 48px auto;

  @media screen and (max-width: $medium-width) {
    margin-bottom: 40px;
  }

  div {
    width: 180px;
  }

  .submitBtn {
    padding: 10px 20px;
  }
}

.dialogContent {
  @include mixins.scrollbar();
}

.deactivate-key-modal {
  .actionGroup {
    .submitBtn {
      border: 1px solid #EB6161;
      background-color: #EB6161;

      &:hover {
        border: 1px solid #EB6161;
        background: #EB6161;
      }
    }
  }
}

.users-delete-modal {
  .actionGroup {
    .submitBtn {
      border: 1px solid #EB6161;
      background-color: #EB6161;

      &:hover {
        background: #EB6161;
      }
    }
  }
}

.recipients-modal,
.recipients-modal-nickname {
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .actionGroup {
    @media screen and (max-width: 480px) {
      margin-right: 16px;
      margin-left: 16px;

      div {
        max-width: 50%;
        font-size: 14px;
      }
    }
  }
}

.recipients-modal-nickname {
  .actionGroup {
    width: 82%;

    div {
      width: 100%;
    }
  }
}

@media screen and (max-width: ($small-width + 280px)) {
  ul li {
    margin-left: 0 !important;
    padding-left: 7px !important;
    min-height: 20px !important;
  }
}

.tooltip {
  padding: 0;
  background-color: transparent;
}

.autocompleteMenuList {
  padding: 9px 8px;
  background-color: #fff;
  z-index: 999;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #c4c4c4;
  border-radius: 15px 15px 0 0;
  margin-top: 10px !important;

  .noData {
    padding: 8px;
    @include mixins.font(24px, 16px);
    color: $darkest-blue;
  }

  .autocompleteMenuItem {
    padding: 8px;
    border-radius: 10px;

    &:hover {
      background-color: rgba(0, 160, 155, 0.1)
    }

    .menuItemTitle {
      @extend .space-between;

      >p {
        @include mixins.font(20px, 16px);
        @include mixins.fontWeight(500);
      }

      >span {
        @include mixins.font(20px, 14px);
        color: $dark-grey;
      }
    }

    .menuItemSnippet {
      margin-top: 2px;
      @include mixins.font(18px, 14px);
    }
  }
}

.details {
  background-color: white;
  z-index: 999;
  height: 62px;
  border: 1px solid #c4c4c4;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top: 0;
  @extend .align-items-center;

  @media screen and (max-width: ($small-width + 40)) {
    height: 42px !important;
  }

  p {
    @include mixins.font(32px, 16px);
    color: #777777;
    margin-left: 9px;

    @media screen and (max-width: ($small-width + 40)) {
      @include mixins.font(14px, 12px)
    }

    button {
      @extend .transparent;
      font-size: 16px;
      z-index: 1000;
      color: $merge-turquoise;
    }
  }
}

.selectFormVariant {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 15px;
  height: 56px;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
}

.currencySuffix {
  display: flex;
  align-items: center;
}

.pagination {
  margin-top: 40px;
  margin-bottom: 80px;

  .select {
    display: flex;
    justify-content: flex-end;
    width: 400px;
    align-items: center;

    p {
      margin-right: 8px;
      @include mixins.font(20px, 14px);
      letter-spacing: 0.25px;
      color: #454D59;
    }

    &Field {
      max-width: 76px;

      @media screen and (max-width: 355px) {
        max-width: 65px;
      }

      >div {
        @include mixins.font(20px, 14px);

      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    @include mixins.font(20px, 14px);

    svg {
      height: 16px;
      width: 9.5px;
    }

    &Page {
      margin-left: 36px;
      color: #016C87;
    }

    &PerPage {
      margin-right: 24px;
      color: #00072B;
    }

    &Slash {
      margin-right: 24px;
      margin-left: 24px;
      color: #B0BAC9;
    }

    @media screen and (max-width: 580px) {
      &Page {
        margin-left: 12px;
      }

      &PerPage {
        margin-right: 12px;
      }

      &Slash {
        margin-right: 12px;
        margin-left: 12px;
      }
    }
  }
}

.selectAccount {
  display: flex;
  flex-direction: column;
  .currencyPicker {
    width: 140px;
    height: auto;
    border-radius: 15px;
    position: relative;
    z-index: 2;
    margin-bottom: -15px;
    margin-left: 10px;
    margin-left: 26px;
    background-color: #fff;

    .currencySelect {
      height: auto !important;
    }
  }

  .accountHolder {
    background-color: #F7F8FC;
    padding: 24px;
    box-sizing: border-box;
    height: 212px;
    border: 0px;
    box-shadow: unset !important;

    .accountCard {
      background-color: #FBFBFB;
      padding: 8px 24px;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      height: 152px;
      box-sizing: border-box;
    }

    .close {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: -10px;
      position: relative;
      right: -5px;
    }
  }
}

.selectPicker {
  display: flex;
  flex-direction: column;
  gap: 54px;

  .calendarSelect {
    display: flex;
    gap: 16px;
    background-color: #F7F8FC;
    padding: 24px 16px;
    box-sizing: border-box;
    height: 212px;
    border: 0px;
    box-shadow: unset !important;

    .selectBox {
      border: 0.5px solid #666666;
      border-radius: 8px;
      background-color: #FFF;

      > div > div {
        justify-content: flex-start !important;
        padding: 24px 30px;
      }
    }
    
    .year {
      flex: 1.5;
      height: auto !important;

      &:focus {
        border-color: #016C87;
        box-shadow: 0 0 5px #016C87;
      }
    }
    .month {
      flex: 2;
      height: auto !important;

      &:focus {
        border-color: #016C87;
        box-shadow: 0 0 5px #016C87;
      }
    }
  }
}


.selectAccountItem {
  @extend .selectMenuItem;
  border-radius: 0px !important;
  border-bottom: 1px solid rgb(229, 229, 229) !important;

  &:last-child {
    border-bottom: 0px !important;
  }
}

.multiSelectPlaceholder {
  color: rgba(0, 0, 0, 0.36);
  font-size: 16px;
  font-weight: 300;
}

.accountMenuItem {
  width: 100%;
  padding: 8px 0px;

  .accountDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;

    .accountName {
      display: flex;
      gap: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      .typeTag {
        line-height: 20px;
        height: 20px;
        font-size: 12px;
        font-weight: 500;
        // border: 1px solid #13194A;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 160, 155, 0.05);
        border-radius: 10px;
        padding: 1px 10px;
      }
    }

    .nickname {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }

    .balanceLabel {
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      text-align: right;
    }

    .balance {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  .identifiers {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 8px;

    .data {
      width: 50%;
      padding: 4px 0px;
      display: flex;
      align-items: center;
      color: #666666;
      height: 22.5px; // To fill empty values also

      .idLabel {
        font-size: 12px;
        font-weight: 800;
        width: 50px;
      }

      .idValue {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

}

.paymentInputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  // justify-content: space-between;

  .paymentAccounts {
    display: flex;
    width: 100%;
    gap: 50px;

    .paymentInputSelect {
      width: 50%;
    }
  }

  .label {
    font-weight: 600;
    font-size: 16px;
    color: #111326;
  }

  .error {
    color: $error-red;
    margin-top: 12px;
  }

  .amountInput {
    width: 100%;
    margin-top: 32px;
    display: flex;
    align-items: center;
    height: 115px;
    justify-content: space-between;

    .source {
      width: 33%;
      // padding-right: 30px;
    }

    .quoteInfo {
      padding: 0px 32px;
      // width: 33%;
      flex: 1 auto;

      .quoteLabelContainer {
        display: flex;
        justify-content: center;

        .quoteLabel {
          position: relative;
          font-size: 14px;
          font-weight: 500;
          color: #666666;
          top: 10px;
          // left: 20px;
          padding: 0px 5px;
          width: fit-content;
          background: #fff;
        }
      }

      .fetchQuote {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 270px;
        height: 87px;
        padding: 14px 31px 14px 17px;
        border-radius: 8px;
        background-color: #FAFBFD;

        .exchangeRate {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #111111;
          line-height: 16px;
          margin-bottom: 12px;

          .currency {
            margin: 0px 5px;
            width: 16px;
            height: 16px;
          }

          .refresh {
            margin: 0px 3px;
            width: 28px;
            height: 28px;
          }
        }

        .feeAmount {
          margin-top: 12px;
          font-size: 14px;
          font-weight: 500;
          color: #666666;
        }

        .quoteText {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #666666;
        }

        .fetchBtn {
          width: 180px;
          margin-top: 10px;
          background: #3190E4;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .loading {
        width: 100%;
        height: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
      }
    }

    .destination {
      width: 33%;
    }

    .amountValue {
      margin-top: 11px;
      font-size: 18px;
      font-weight: 400;
      color: #2F2B3D;
      line-height: 56px;
    }
  }

  .reference {
    width: 33%;
    margin-top: 42px;
    padding-right: 20px;

    .readmode {
      font-size: 16px;
      font-weight: 500;
      color: #2F2B3D;
      line-height: 40.86px;
    }
  }

  .paymentActions {
    display: flex;
    justify-content: center;
    padding: 16px;

    .submitButton {
      width: 240px;
      padding: 5px;
      border-radius: 15px;
    }
  }

  .editButtonTooltip {
    display: flex;
    justify-content: flex-end;
    color: #1C96E4;
    margin-left: auto;
    margin-bottom: -20px;
    z-index: 5;
    position: relative;
    cursor: pointer;

    .editIcon {
      color: #1C96E4;
      width: 24px;
      height: 24px;
      margin-left: 5px;

      path {
        fill: #1C96E4;
      }
    }
  }


  .editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 36px;
    border-radius: 6px;
    color: #1C96E4;
    border: 1px solid #1C96E4;
    margin-left: auto;
    margin-right: 50px;
    margin-bottom: -50px;
    z-index: 5;
    position: relative;
    cursor: pointer;

    .editIcon {
      color: #1C96E4;
      width: 16px;
      height: 16px;
      margin-left: 5px;

      path {
        fill: #1C96E4;
      }
    }
  }
}

.dialogV2 {
  .dialogHeader {
    padding: 0px;
    display: block;
    width: 100%;

    .closeDialog {
      position: absolute;
      right: 18px;
      top: 29px
    }

    .title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      line-height: 26.63px;
      color: #111326;
    }
  }

  .dialogChildren {
    margin-top: 30px;
    display: flex;
    gap: 42px;
    align-items: center;
    padding: 0 !important;
    .dialogContent {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
  }

  .dialogActions {
    display: flex;
    justify-content: center;
    gap: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    margin-top: 24px;
    .dialogSubmitBtn {
      box-sizing: border-box;
      border-radius: 12px;
      min-width: 194px;
      height: 42px;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-transform: none;
      }
    .dialogCancelBtn {
      box-sizing: border-box;
      border-radius: 12px;
      min-width: 194px;
      height: 42px;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      text-transform: none;
    }

    .criticalSubmitBtn {
      box-sizing: border-box;
      border-radius: 12px;
      background-color: #00A09B;
      color: #fff;
      text-transform: none;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      padding: 8px 24px;
    }
    
    .criticalCancelBtn {
      box-sizing: border-box;
      border-radius: 12px;
      background-color: #EB6161;
      color: #fff;
      text-transform: none;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      padding: 8px 24px;
    }

    .modal-info {
      background-color: #203F7C;
      color: #fff;

      &-outlined {
        color: #203F7C;
        background-color: #fff;
      }
    }

    .modal-error {
      background-color: #203F7C;
      color: #fff;

      &-outlined {
        color: #203F7C;
        background-color: #fff;
      }
    }

    .modal-success {
      background-color: $merge-turquoise;
      color: #fff;

      &-outlined {
        color: $merge-turquoise;
        background-color: #fff;
      }
    }

    .modal-warning {
      background-color: #203F7C;
      color: #fff;

      &-outlined {
        color: #203F7C;
        background-color: #fff;
      }
    }

    .modal-warning-terminal {
      background-color: #203F7C;
      color: #fff;

      &-outlined {
        color: #203F7C;
        background-color: #fff;
      }
    }

  }
}

.customListPrimary {
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
}

.customListSecondary {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}

.autoCompleteMenu {
  @include mixins.scrollbar();
}

.txnId {
  color: $merge-turquoise;
  cursor: pointer;
  text-decoration: underline;
  // font-size: 15px;
  font-weight: 700;
}

.txnModal {
  .paymentError {
    display: flex;
    gap: 5px;
    height: 50px;
    align-items: flex-start;

    .plabel {
      font-size: 16px;
      font-weight: 500;
      padding: 5px 0px;
      color: rgba(102, 102, 102, 1);
      line-height: 28px;
    }

    .pvalue {
      font-size: 14px;
      font-weight: 500;
      padding: 5px 0px;
      color: rgba(17, 17, 17, 1);
      line-height: 28px;
    }
  }

  .paymentType {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: rgba(17, 17, 17, 1);
    // margin-bottom: 20px;
  }

  .summary {
    display: flex;

    .accountInfo {
      width: 35%;
      min-width: 340px;
      min-height: 100px;
      border-radius: 15px;
      padding: 15px;
      background-color: rgba(251, 251, 251, 1);

      .txndetails {
        display: flex;
        align-items: center;
        gap: 10px;

        .card {
          .clabel {
            font-size: 14px;
            line-height: 20px;
            color: rgba(102, 102, 102, 1);
            font-weight: 500;
          }

          .cvalue {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
          }
        }
      }

      .amountInfo {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;

        .alabel {
          font-size: 12px;
          line-height: 16px;
          font-weight: 700;
          color: rgba(102, 102, 102, 1);
        }

        .amount {
          line-height: 25px;
          font-size: 20px;
          font-weight: 600;
        }

        .time {
          line-height: 25px;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .identifiersInfo {
        margin-top: 20px;

        .identifier {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 5px;

          .ilabel {
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            color: rgba(102, 102, 102, 1);
          }

          .ivalue {
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: rgba(102, 102, 102, 1);
          }
        }
      }
    }

    .transferInfo {
      width: 30%;

      .transferLine {
        border-top: 1px dashed #bbb;
      }

      .txnStatus {
        margin-top: -20px;
        height: 30px;
        padding-top: 10px;
        width: 160px;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        border-radius: 6px;
        margin-left: auto;
        margin-right: auto;
      }

      .successful {
        background-color: #EBF7F7;
        color: $merge-turquoise;
      }

      .processing {
        background-color: #FEF5E6;
        color: rgb(204, 123, 3);
      }

      .failed {
        background-color: #FFEFEF;
        color: rgba(255, 49, 49, 1);
      }

      .exchangeRate {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        color: rgb(17, 17, 17);
      }

      .quoteId {
        background-color: rgba(245, 247, 255, 0.3);
        border: 1px solid rgba(112, 123, 162, 1);
        border-radius: 8px;
        width: fit-content;
        margin: 10px auto 0px auto;

        .header {
          text-align: left;
          margin-top: -6px;
          margin-left: 10px;
          width: fit-content;
          font-size: 10px;
          font-weight: 700;
          background: #fff;
          color: rgba(112, 123, 162, 1);
        }

        .value {
          text-align: center;
          color: rgba(146, 154, 182, 1);
          font-size: 12px;
          padding: 12px;
          font-weight: 500;
        }
      }
    }
  }

  .misc {
    margin-top: 30px;
    display: flex;

    .panel {
      width: 50%;
      padding: 0px 20px;
      margin: 0px 10px;
      border-radius: 6px;
      border: 1px solid rgba(229, 229, 229, 1);

      .createdAt {
        margin-top: -7px;
        width: 200px;
        background: #fff;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        color: #666666;
      }

      .plabel {
        font-size: 16px;
        font-weight: 500;
        padding: 5px 0px;
        color: rgba(102, 102, 102, 1);
        line-height: 28px;
      }

      .pvalue {
        font-size: 14px;
        font-weight: 500;
        padding: 5px 0px;
        color: rgba(17, 17, 17, 1);
        line-height: 28px;
      }
    }
  }

  .feeSummary {
    display: flex;

    .feeDetails {
      padding: 20px;
      width: 60%;
      background-color: rgba(251, 251, 251, 1);

      .accDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .card {
          margin-left: 10px;

          .clabel {
            font-size: 14px;
            line-height: 20px;
            color: rgba(102, 102, 102, 1);
            font-weight: 500;
          }

          .cvalue {
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
          }
        }

        .status {
          padding: 10px 20px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;

        }

        .successful {
          background-color: rgba(0, 160, 155, 0.08);
          color: $merge-turquoise;
        }

        .processing {
          background-color: rgb(254, 245, 230);
          color: rgb(204, 123, 3);
        }

        .failed {
          background-color: rgba(255, 49, 49, 0.08);
          color: rgba(255, 49, 49, 1);
        }

      }

      .txnInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
        margin-top: 20px;

        .data {
          display: flex;
          flex-direction: column;
          height: 44px;

          .dlabel {
            height: 20px;
            font-size: 12px;
            font-weight: 700;
            line-height: 12px;
            color: rgba(102, 102, 102, 1);
          }

          .time {
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: rgba(17, 17, 17, 1);
            height: 20px;
          }

          .amount {
            height: 20px;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            color: rgba(17, 17, 17, 1);
          }
        }

      }

      .identifiersInfo {
        margin-top: 20px;

        .identifier {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 5px;
          height: 45px;

          .ilabel {
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            color: rgba(102, 102, 102, 1);
          }

          .value {
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: rgba(102, 102, 102, 1);
          }
        }
      }
    }

    .miscFee {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .panel {
        padding: 10px 20px;
        margin-left: 10px;
        min-height: 120px;
        border-radius: 6px;
        border: 1px solid rgba(229, 229, 229, 1);
        align-content: center;
        height: 100%;

        .divider {
          display: block;
          height: 1px;
          border: 0;
          border-top: 0.2px solid #BBBBBB;
          padding: 0;
          margin: 10px 0px;
        }

        .plabel {
          font-size: 16px;
          font-weight: 500;
          padding: 5px 0px;
          color: rgba(102, 102, 102, 1);
          line-height: 28px;
        }

        .value {
          font-size: 14px;
          font-weight: 500;
          padding: 5px 0px;
          color: rgba(17, 17, 17, 1);
          line-height: 28px;
        }
      }
    }
  }

  .loading {
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}

.paymentValueContainer {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: rgba(238, 238, 238, 0.3);

  .paymentValueLabel {
    display: flex;
    padding: 0px 15px;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    height: 66px;
    align-items: center;
  }

  .paymentValue {
    display: flex;
    align-items: center;
    padding-left: 14px;
    height: 66px;
    border-left: 0.5px solid #C4C4C4;
    flex: 1;
    color: #666666;

    &.paymentText {
      font-size: 16px;
      font-weight: 400;
    }

    &.paymentNumber {
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.tabList {
  display: flex;

  .tabButton {
    padding: 10px 0;
    width: 224px;
    text-align: center;
    border: none;
    cursor: pointer;
    background: none;
    border-radius: 10px;
    color: #999999;
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
    transition: background-color 200ms ease-in;
  }

  .active {
    background: #00A09B1A;
    color: #00A09B;
    font-weight: 700;
  }
}

.timerText {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  width: 58px;
  text-align: center;
}

.toasterBody-warning {
  position: fixed;
  top: 20px;
  right: 30px;
  background-color: #FFF7F0;
  min-width: 676px;
  height: 44px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 #0000001A;
}
