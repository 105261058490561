@use "variables" as *;

@mixin scrollbar($inset: "") {
  &::-webkit-scrollbar {
    width: 14px unquote($inset);
    height: 18px unquote($inset);
  }
  &::-webkit-scrollbar-thumb {
    height: 6px unquote($inset);
    border: 4px solid rgba(0, 0, 0, 0) unquote($inset);
    background-clip: padding-box unquote($inset);
    border-radius: 7px unquote($inset);
    background-color: rgba(0, 160, 155, 0.3) unquote($inset);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05) unquote($inset);
  }
  &::-webkit-scrollbar-button {
    width: 0 unquote($inset);
    height: 0 unquote($inset);
    display: none unquote($inset);
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent unquote($inset);
  }
}

@mixin links {
  color: #00A09B;
  cursor: pointer;
}

@mixin font($lineHeight, $size, $font: $font ){
  font: $size $font;
  line-height: $lineHeight;
}

@mixin fontWeight($weight){
  font-style: normal;
  font-weight: $weight;
}

@mixin absolute($top, $right, $bottom, $left){
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin outlinedButton($background, $color) {
  border: 1px solid $color;
  box-sizing: border-box;
  // filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  color: $color;
  padding: 14px;
  background: $background;

  &:hover {
    background: $semi-transparent;
    border: 1px solid $color;
    color: $white;
    // border: inherit;
  }  
  >p {
    @include font(26px, 18px);
    color: $color;
  }

}

@mixin submitButton($background, $color) {
  border: none;
  outline: none;
  background: $background;
  border-radius: 15px;
  padding: 14px;
  width: 100%;
  >p {
    @include font(26px, 18px);
    color: $color;
  }
  &:hover {
    background: $button-gradient;
  }
  &:active {
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
  }
}

@mixin input($color) {
  border: 1px solid $color;
  box-sizing: border-box;
  border-radius: 15px ;
}

@mixin inputFocus($color) {
  border-radius: 15px !important;
  border-color: $color;
  box-shadow: 0 0 5px $color;
  //border-color: #00A09B;
  //border-image: linear-gradient(0deg, #00A09B, #016C87) 1;
  //background: linear-gradient(45deg, #fff, #fff),
  //linear-gradient(to left, #00A09B, #016C87);
  //background-origin: padding-box, border-box;
  //background-repeat: no-repeat;
  //border: 1px solid transparent;
  //border-color: $dark-turquoise !important;
  //box-shadow: 0 0 5px $dark-turquoise !important;
  //box-sizing: border-box;
  //border-radius: 15px;

}

@mixin border($color, $style: solid, $size: 1px) {
  border: $size $style $color;
  color: $color
}

@mixin marginVertical($size) {
  margin-top: $size;
  margin-bottom: $size;
}

@mixin marginHorizontal($size) {
  margin-right: $size;
  margin-left: $size;
}

@mixin paddingVertical($size) {
  padding-top: $size;
  padding-bottom: $size;
}

@mixin paddingHorizontal($size) {
  padding-left: $size;
  padding-right: $size;
}

@mixin iconColor($color) {
  svg {
    fill: $color;
    path {
      fill: $color;
      fill-opacity: 1;
    }
  }
}

@mixin iconStyles($color, $disabled-color: $light-grey) {
  @include iconColor($color);

  &[disabled] {
    pointer-events: none;
    @include iconColor($disabled-color);
  }
}
