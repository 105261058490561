@use "src/assets/styles/common/variables.scss" as *;
@use "src/assets/styles/common/mixins.scss" as mixins;
@use "src/assets/styles/common/global.scss" as *;
@use "src/assets/styles/common/functions.scss" as *;

.container {
    padding-top: 17px;
    padding-left: 31px;
    max-width: 980px;
    @media screen and (max-width: 520px) {padding-left: 18px; padding-right: 18px !important}
    @media screen and (max-width: 899px) {padding-top: 25px; padding-right: 31px}
  
    .title {
      color: $darkest-blue;
      @include mixins.font(36px, 32px);
      @media screen and (max-width: 460px){@include mixins.font(24px, 20px);}
    }

    .userInfo {
        margin-top: 30px;
        h5 {
            display: flex;
            align-items: center;
            width: 80%;
            padding:0% 5%;
            svg {
                margin: 4px 18px;
            }
            span {
                font-weight: 400;
                font-size: 20px;
            }
            .changePwdBtn {
                font-weight: 400;
                width: 220px;
                font-size: 20px;
                margin-left: auto;
            }
        }
    }
    .userRoles {
        margin-top: 20px;
        display: flex;
        align-items: center;
        width: 80%;
        padding:0% 5%;
        h5 {
            font-size: 20px;
            margin: 4px 18px;
        }
        span {
            font-weight: 400;
            font-size: 20px;
        }
    }
    .userActions {
        margin-top: 20px;
        display: flex;
        align-items: center;
        width: 80%;
        padding:0% 5%;
        .changePwdBtn {
            font-weight: 400;
            width: 220px;
            font-size: 20px;
        }
    }

    .changePassword {
        margin-top: 20px;
        .form {
            width: 50%;
        }

        .pwdStrengh {
            margin-top: 15px;
        }          
    }

    .details {
        margin-top: 50px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        .datarow {
            display: flex;
            width: 100%;
            flex-direction: row;
            margin: 10px;
            .header {
                color: $dark-grey;
                line-height: 20px;
                margin-right: 30px;
                width: 150px;
              }
              .value {
                line-height: 20px;
              }    
        }
    }
    .data {
        margin-top: 30px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        .datacol {
          display: flex;
          flex-direction: column;
          margin-right: 60px;
          gap: 10px;
          .header {
            color: $dark-grey;
            line-height: 20px;
            text-align: center;
          }
          .value {
            line-height: 20px;
          }
        }
        span {
            font-weight: 400;
            font-size: 15px;
        }
    }

    .error {
        margin-top: 38px;
        color: #FF8888;
    }
}
.confirmationDialog {
    .confirmation {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 30px 20px 30px;
        .otp {
            font-weight: 700;
            font-size: 20px;
        }
    }
}
