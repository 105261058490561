@use "src/assets/styles/common/variables.scss" as *;
@use "src/assets/styles/common/mixins.scss" as mixins;
@use "src/assets/styles/common/global.scss" as *;
@use "src/assets/styles/common/functions.scss" as *;

.container {
  padding-top: 17px;
  padding-left: 32px;
  padding-right: 32px;

  @media screen and (max-width: 520px) {
    padding-left: 18px;
    padding-right: 18px !important
  }
  @media screen and (max-width: 899px) {
    padding-top: 25px;
    padding-right: 31px
  }

  .title {
    color: $darkest-blue;
    @include mixins.font(36px, 32px);
    @media screen and (max-width: 460px) {
      @include mixins.font(24px, 20px);
    }
  }

  .tableHead th {
    color: $darkest-blue;
    font-weight: 700 !important;
    @include mixins.font(22px, 14px);
    &:first-child {
      max-width: 150px;
    }
  }

  .headerRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .rolesCell {
    max-width: 145px;
  }

  .createUserBtn {
    background: $merge-turquoise;
    border-radius: 15px;
    gap: 10px;
    font-weight: 400;
    width: 180px;
    font-size: 14px;
    line-height: 26px;
    cursor: pointer;

    &[disabled] {
      background: $dark-grey;
      color: $white;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .error {
    margin-top: 38px;
    color: #FF8888;
  }

  .loading {
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 80px;
    display: flex;
    align-items: center;
  }
  .noData {
    color: $dark-grey;
    font-weight: 500;
    margin: 100px auto
  }
  .addButton {
    width: 211px;
    @media screen and (max-width: 600px) {  width: 100%;}
  }

  .view {
    &[disabled] {
      cursor: not-allowed;
      // pointer-events: none;
    }
    .editUserButton {
      fill: #191C49;
      path {
        fill: #191C49;
      }
    }
    .deleteUserButton {
      cursor: pointer;
      &Inactive{
        cursor: not-allowed;
        fill: #C4C4C4;
        path {
          fill: #C4C4C4;
        }
      }
    }
  }
}

.confirmationDialog {
  .confirmation {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 30px 20px 30px;

    .errorMsg {
      text-align: left;
      margin: 10px 0px;
      color: $error-red !important;
      @include mixins.font(1.5, 18px);
    }

    .successMsg {
      text-align: left;
      margin: 10px 0px;
      color: $merge-turquoise !important;
      @include mixins.font(18px, 18px);
      font-weight: 700;
    }
    
    .otp {
      font-weight: 700;
      font-size: 20px;
    }

    .gridRow {
      display: flex;
      gap: 16px;
      justify-content: space-between;

      .gridRowEl {
        flex: 1;
        margin-bottom: 8px;

        .editUserButton {
          fill: #191C49;
          path {
            fill: #191C49;
          }
        }
    
        input {
          height: 40px;
          padding: 8px;
          border-radius: 10px !important;
        }
      }
    }

    .userWrapper {
      padding: 8px 0 0;

      .usersRadioLabel {
        padding-top: 16px;
        text-align: left;
        margin-right: 16px;
      }
      .usersRolesLabel {
        padding-top: 10px;
        text-align: left;
        margin-right: 16px;
      }
    }

    .cancelBtn {
      border-radius: 10px;
      border: 1px solid var(--merge-turquiose-100, #00A09B);
      width: 100%;
      display: flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      color: var(--merge-turquiose-100, #00A09B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin-top: 32px;
    }

    .actionBtn {
      display: flex;
      width: 100%;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      border: 1px solid var(--merge-turquiose-100, #00A09B);
      background: var(--merge-turquiose-100, #00A09B);
      color: var(--pure-white-100, #FFF);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin-top: 32px;
    }
    .actionBtn[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .rolesWrapper {
      flex-direction: row;
    }

    .invalid {
        margin-top: 8px;
        margin-left: 0;
        color: $error-red !important;
        @include mixins.font(18px, 14px);
    }    
  }
}

.userReview{
  .review-sub-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: rem(18);
    line-height: rem(20);
    margin: rem(16) 0 rem(26);
  }
  .review-detail {
    font-style: normal;
    font-weight: normal;
    font-size: rem(18);
    line-height: rem(20);
    color: $dark-grey;
    min-height: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
    @media screen and (max-width: 480px) {font-size: 14px;}
    .label {
      width: 160px;
    }
    .item {
      display: flex;
      font-size: rem(16);
      color: $darkest-blue;
      word-break: break-all;
      gap: 15px;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {font-size: 14px;}
  }
  .review-step {
    margin-right: 8.33%;
  }
}
.errorMsg {
  margin: 10px 0px;
  color: $error-red !important;
  @include mixins.font(18px, 18px);
}

.successMsg {
  margin: 10px 0px;
  color: $merge-turquoise !important;
  font-weight: 700;
  @include mixins.font(18px, 18px);
}

.modal-content{
  p {
    line-height: 1.7;
    text-align: center;
    margin: 10px 0px 10px 0px;
  }
}