@use "src/assets/styles/common/variables.scss" as *;
@use "src/assets/styles/common/mixins.scss" as mixins;
@use "src/assets/styles/common/global.scss" as *;
@use "src/assets/styles/common/functions.scss" as *;

.container {
  padding-top: 17px;
  padding-left: 31px;
  padding-right: 30px;

  @media screen and (max-width: 520px) {
    padding-left: 18px;
    padding-right: 18px !important
  }

  @media screen and (max-width: 899px) {
    padding-top: 25px;
    padding-right: 31px
  }

  .searchButton {
    height: 40px;
    padding: 7px 54px;
    width: 166px;
    margin-right: 10px;
    @include mixins.font(26px, 18px);

    @media screen and (max-width: 420px) {
      padding: 7px 18px;
    }
  }

  .filterSearch {
    margin-top: 20px;
  }

  .header {
    display: flex;
    gap: 10px;
    align-items: center;

    .title {
      color: $darkest-blue;
      @include mixins.font(36px, 32px);

      @media screen and (max-width: 460px) {
        @include mixins.font(24px, 20px);
      }
    }
  }

  .loading {
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 80px;
    display: flex;
    align-items: center;
  }

  .status {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 820px) {
      >div:not(.accountSearch) {
        width: 75%;
        margin-right: 20px;
      }
    }
  }

  .clear {
    position: absolute;
    right: 210px;
    top: 13px
  }

  .noData {
    color: $dark-grey;
    font-weight: 500;
    margin: 100px auto
  }

  .list {
    .table {
      @include mixins.marginVertical(40px);

      th {
        color: $darkest-blue;
        font-weight: 700 !important;
        @include mixins.font(22px, 14px);

        &:first-child {
          padding-left: 34px;
          max-width: 150px;
        }
      }

      tbody {
        tr td {
          border-bottom: none !important;

          .name {
            display: flex;

            svg {
              margin-top: 6px;
              margin-right: 20px;
            }
          }

          b {
            font-weight: 600;
          }

          p {
            color: $darkest-blue;
            max-width: 200px;
            @include mixins.font(24px, 18px);
          }

          .view {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    .item {
      height: 96px;
      border-top: 1px solid $lightest-grey;
      font-size: 14px;

      @media screen and (max-width: 580px) {
        height: 127px;
      }

      p {
        @media screen and (max-width: 540px) {
          max-width: 160px;
          margin-bottom: 18px;
        }
      }

      .type {
        right: 180px;
        position: absolute;
        width: 200px;
        text-align: right;
        @include mixins.font(22px, 18px);

        @media screen and (max-width: 580px) {
          text-align: left;
          left: 73px;
          top: 90px
        }
      }

      .subType {
        @extend .type;
        right: 14%;
      }

      .avatar {
        width: 47px;
        height: 47px;
        background: $merge-turquoise-light;
        color: $merge-turquoise;
        margin-right: 17px;
        font-size: 16px;

        &Disable {
          color: #777777;
        }
      }

      &:last-child {
        border-bottom: 1px solid $lightest-grey;
      }

      .action {
        margin: 0 20.5px;

        svg {
          vertical-align: middle;
          fill: $light-grey;
        }

        @media screen and (max-width: 580px) {
          margin-top: 50px;
        }

        @media screen and (max-width: 400px) {
          margin-right: 0;
        }
      }

      .statusIcon {
        position: absolute;
        right: 105px;

        @media screen and (max-width: 680px) {
          right: 60px;
        }

        @media screen and (max-width: 580px) {
          top: 45px;
          right: 31px;
        }

        @media screen and (max-width: 400px) {
          right: 10px;
        }
      }

      .subStatusIcon {
        @extend .statusIcon;
        right: 23%;

        @media screen and (max-width: 580px) {
          right: 18px;
        }
      }

      .subStatusRef {
        position: absolute;
        left: 46%;
        width: 35%;
        top: 30px;
      }

      .subStatusRefNoData {
        @extend .subStatusRef;
        top: 30px
      }

    }

    .subItem {
      height: 84px;

      @media screen and (max-width: 880px) {
        height: 112px;

        div:nth-child(2) {
          margin-bottom: 40px;
        }
      }

      @media screen and (max-width: 540px) {
        height: 140px;

        div:nth-child(2) {
          margin-bottom: 18px;
        }
      }

      @media screen and (max-width: 440px) {
        height: 150px;

        div:nth-child(2) {
          margin-bottom: 36px;
        }
      }
    }

    .createdDate {
      @include mixins.font(20px, 14px);
      @include mixins.fontWeight(600);
      color: $light-grey;
      margin-bottom: 24px;
      margin-top: 8px;
    }

    .seeTransaction {
      color: $merge-turquoise;
      background-color: transparent;
      border: none;
      @include mixins.font(22px, 18px);
      text-decoration-line: underline;
      right: 35px;
      top: 42%;
      position: absolute;

      @media screen and (max-width: 880px) and (min-width: 541px) {
        top: 73px !important;
      }

      @media screen and (max-width: 540px) and (min-width: 441px) {
        top: 100px !important
      }

      @media screen and (max-width: 440px) {
        top: 100px !important;
      }

      svg {
        fill: $merge-turquoise;

        path {
          fill: $merge-turquoise;
        }
      }
    }

    .accountBalance {
      position: absolute;
      left: 72%;
      top: 40%;
      font-weight: 600;

      @media screen and (max-width: 880px) and (min-width: 541px) {
        left: 98px;
        top: 70px !important
      }

      @media screen and (max-width: 540px) {
        top: 100px !important;
        left: 98px;
      }

    }

    .actions {
      display: flex;

      span,
      button {
        position: absolute;
        @include mixins.font(22px, 18px);
        top: 40%;
      }

      &Approve1 {
        position: absolute;
        right: 38%;
        text-align: right;
        @include mixins.font(22px, 18px);

        @media screen and (max-width: 880px) and (min-width: 541px) {
          right: 8px;
          top: 18px
        }

        @media screen and (max-width: 540px) and (min-width: 441px) {
          right: 8px;
          top: 24px;
          width: unset;
        }

        @media screen and (max-width: 440px) {
          top: 132px;
          left: 76px;
          width: 250px;
        }
      }

      &Decline {
        position: absolute;

        right: 70px;
        top: 40%;

        @media screen and (max-width: 880px) and (min-width: 541px) {
          top: 73px !important
        }

        @media screen and (max-width: 540px) and (min-width: 441px) {
          top: 100px !important;
        }

        @media screen and (max-width: 440px) {
          top: 100px !important;
        }
      }

      &Approve {
        top: 40%;
        position: absolute;
        left: 61%;
        margin-top: 8px;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 18px;
        margin-left: 72px;

        @media screen and (max-width: 880px) and (min-width: 541px) {
          left: 4px;
          top: 70px;
          margin-bottom: 18px;
        }

        @media screen and (max-width: 540px) {
          top: 100px;
          left: 4px;
          margin-bottom: 18px;
        }
      }

      &SendFund {
        position: absolute;
        color: $merge-turquoise;
        background-color: transparent;
        border: none;
        @include mixins.font(22px, 18px);
        top: 42%;
        right: 0px;

        @media screen and (max-width: 880px) and (min-width: 541px) {
          top: 73px !important;
        }

        @media screen and (max-width: 540px) and (min-width: 441px) {
          top: 100px !important
        }

        @media screen and (max-width: 440px) {
          top: 100px !important;
        }

        svg {
          fill: $merge-turquoise;

          path {
            fill: $merge-turquoise;
          }
        }

      }
    }
  }
}

.pendingPaymentReview {
  .review-sub-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: rem(18);
    line-height: rem(20);
    margin: rem(16) 0 rem(26);
  }

  .review-detail-label {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: rem(18);
    line-height: rem(20);
    color: $dark-grey;
    margin-bottom: rem(16);
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
  }

  .review-detail-item {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: rem(16);
    line-height: rem(20);
    color: $darkest-blue;
    margin-left: rem(10);
    word-break: break-all;
    text-align: right;

    &.error {
      color: $error-red
    }

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
  }

  .review-step {
    margin-right: 8.33%;
  }
}

.paymentsFilter {
  width: 250px;
  margin-right: 13px;

  input {
    height: 40px;
  }
}

.paymentSearchField {
  margin-top: 12px;
  max-width: 250px;
  box-sizing: border-box;
  border-radius: 15px;
  border: none !important;
  box-shadow: (0 0 10px rgba(0, 0, 0, 0.25));

  >div {
    max-height: 0 !important;
    padding: 20px 0;

    >div {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 550px) {
    max-width: 100%;
  }

  .indeterminateColor {
    color: #f50057;
  }
}

.collapsible {
  width: 100%;
  height: 100%;
  padding: 0 7px 40px 70px;
  background-color: #fff;
  overflow: hidden;
  margin-left: 12px;

  @media screen and (max-width: 899px) {
    padding: 0;
  }

  h3 {
    color: $darkest-blue;
    @include mixins.font(24px, 18px);
    @include mixins.fontWeight(500);
  }

  .details {
    margin-top: 32px;
    padding-right: 49px;

    @media screen and (max-width: 899px) {
      width: 100%;
    }

    @media screen and (max-width: 400px) {
      padding-right: 25px;

      h3 {
        @include mixins.font(24px, 16px);
      }

      p {
        @include mixins.font(20px, 14px);
      }
    }
  }

  .info {
    margin-top: 32px;
    padding-left: 40px;
    padding-right: 32px;

    @media screen and (max-width: 899px) {
      padding-left: 0;
      width: 100%;
    }

    @media screen and (max-width: 400px) {
      padding-right: 25px;

      h3 {
        @include mixins.font(24px, 16px);
      }

      p {
        @include mixins.font(20px, 14px);
      }
    }
  }
}

.date {
  width: 100%;
  font: 16px Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $light-grey;
  margin-top: 24px;
  padding-bottom: 6px;
  border-bottom: 1px solid $lightest-grey;
}

.filter {
  align-items: flex-end;
  margin-top: 30px;
  margin-bottom: 0px;

  &Container {
    min-width: 170px;
    padding-right: 15px !important;
  }

  &Reference {
    margin-bottom: 8px;
  }

  &Picker {
    margin-bottom: 8px;
  }

  &Wrapper {
    min-width: 156px;
    width: 340px;
    padding-right: 15px !important;
  }

  &Search {
    display: flex;
    width: 100%;
    position: relative;
  }

  &Buttons {
    margin-top: 46px;
    margin-bottom: 32px;
    margin-left: 6px;

    button {
      border-radius: 15px;
      padding: 8px 15px;
      border: 1px solid $merge-turquoise;
      background-color: transparent;
      color: $merge-turquoise;
      margin-left: 8px;
      font-size: 18px;

      &:hover {
        background: rgba(0, 160, 155, 0.19)
      }

      &:active {
        filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25))
      }
    }
  }
}

.divider {
  width: 50%;
  height: 1px;
  border-top: 1px solid #e5e5e5;
  margin: 50px 0px 15px;
  margin-left: 25%;
}

.paymentActions {
  display: flex;
  justify-content: center;
  padding: 16px;

  .actionBtn {
    width: 240px;
    padding: 5px;
    border-radius: 15px;
  }
}

.confirm-step {
  text-align: center;

  .paymentSuccess {
    line-height: rem(30);
    color: $darkest-blue;
    margin: 10px 0px 65px 0px;

    h1 {
      color: #111326;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.875rem;
    }

    .paymentInfo {
      margin-top: 20px;
      font-size: 1rem;

      .paymentId {
        color: $merge-turquoise;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .confirm-title-error {
    font-family: Inter, sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: rem(24);
    line-height: rem(30);
    color: $darkest-blue;
    margin-bottom: rem(65);
  }
}

@media screen and (max-width: 480px) {
  .submitButton {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.submitButton {
  width: 33%;
  margin-left: 33%;
  margin-bottom: 50px;
}