@use "common/variables" as *;
@use "common/global" as *;
@use "common/mixins" as mixins;

.content {
  @extend .space-between;
  @media screen and (max-width: $medium-width) {
    flex-direction: column;
  }
}

.container {
  padding: 17px 31px 0px;
  @media screen and (max-width: 520px) {padding-left: 18px; padding-right: 18px !important}
  @media screen and (max-width: 899px) {padding-top: 25px; padding-right: 31px}
}

.title {
  color: #191C49;
  max-width: 70%;
  @include mixins.font(36px, 32px);
  @media screen and (min-width: $medium-width) and (max-width: 1060px) {
    top: -110px;
    max-width: 60%;
  }
  @media screen and (max-width: $medium-width) {
    max-width: 48%;
  }
}

.dashboard {
  padding-left: 31px;
  padding-right: 31px;
  position: relative;

  @media screen and (min-width: $small-width) and (max-width: ($small-width + 200px)) {
    margin-left: 32px;
    margin-right: 32px;
  }
  @media screen and (min-width: ($small-width + 201px)) and (max-width: $medium-width) {
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 40px;
  }
  @media screen and (max-width: ($small-width + 200px)) {
    margin-top: 0;
    @include mixins.marginHorizontal(17px)
  }

  .transactions {
    margin-top: 10px;
    width: 100%;

    @media screen and (min-width: ($small-width + 280px)) and (max-width: $medium-width) { width: 504px; margin: 40px auto}
    @media screen and (min-width: $small-width) and (max-width: ($small-width + 280px)) { width: 100%; margin: 20px auto}
  }

  .currencyScroller {
    overflow-x: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 190px;
    margin: 20px 0px;
    @include mixins.scrollbar();
  }

  .currencyCard {
    width: 140px;
    border: 1px solid #eee;
    border-radius: 15px;
    padding: 20px 15px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    color: #111326;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    background: rgba(0,160,155,0.05);
  }
  .transactionSummary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .viewDetails {
      @include mixins.links();
      display: flex;
      align-items: center;
    }
  }
}

.noData {
  color: $dark-grey;
  font-weight: 800;
  text-align: center;
  margin: 40px auto;
}

.txnList {
  margin-bottom: 20px;
  .txnListData {
    overflow: auto;
    @include mixins.scrollbar()
  }
  .row {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid rgb(229, 229, 229);
    &:nth-of-type(even) {
      background-color: rgba(0,160,155,0.05);
    }
  // hide last border
    &:last-child td, &:last-child th {
      border: 0;
    }

  }
  .header {
    color: $darkest-blue;
    padding: 16px;
    font-weight: 700 !important;
    background-color: rgba(0,160,155,0.19);
    @include mixins.font(22px, 14px);
    display: flex;
    border-bottom: 1px solid #ccc;
  }
  .payment_date {
    width: 230px;
  }
  .amount {
    width: 200px;
  }
  .reference {
    flex: 1;
  }
  .status {
    width: 100px;
    text-align: center;
  }
}
.loading {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
}