.space-between {
  display: flex;
  justify-content: space-between;
}

.align-items-center{
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.transparent {
  background-color: transparent;
  border: none;
  outline: none;
}

.column {
  display: flex;
  flex-direction: column;
}

.gray-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background-color: white;
  border: 1px solid white;
  box-sizing: border-box;
}

.full {
  width: 100%;
  height: 100%;
}

.business-margins {
  margin-top: 72px;
  margin-left: 90px;
}

.disable {
  font: 16px Inter;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 16px;
  color: #C4C4C4;
}

.container-wrapper {
  padding-top: rem(77);
  padding-left: rem(31);
  padding-right: rem(200);
}