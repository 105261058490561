@import "src/assets/styles/global.module";
@import "src/assets/styles/common/functions";
@import "src/assets/styles/common/variables";

.radio-group {
  padding: rem(20) 0 0;

  .label {
    color: $darkest-blue;
    margin-bottom: 10px;
    &.Mui-focused {
      color: $darkest-blue;
    }
  }

  .asterisk {
    color: $error-red;
  }

  .error {
    color: $error-red;
    font-size: rem(14);
    margin: rem(10) 0 0;
  }

  .row {
    flex-direction: row;
    .label {
      margin-bottom: 0px;
    }
  }
  .disabled {
    >div {
      color: $light-grey;
    }

    >span:nth-child(2) {
      color: $light-grey;
    }
  }
}

.mt-32 {
  margin-top: rem(12);
}

.label {
  margin: 0px;
  padding-right: 20px;
}

.report {
  padding: 5px;

  // div {
  //   flex-direction: row;
  // }
}

.users {
  padding: 5px;

  div {
    flex-direction: row;
  }
}