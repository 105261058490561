@import "src/assets/styles/global.module";
@import "src/assets/styles/common/functions";
@import "src/assets/styles/common/variables";

.buttonV2 {
    display: flex;
    border-radius: 15px;
    color: #fff;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
}    
.btn-primary {
    background-color: #00A09B;
    &:hover {
        background: linear-gradient(270deg, #00A09B 0%, #016C87 100%);
    }
    &[disabled] {
        background-color: #BBBBBB;
        &:hover {
            background: #BBBBBB;
            cursor: not-allowed;
        }
    }
}
.btn-secondary {
    border: 1px solid #00A09B;
    color: #00A09B;
    &:hover {
        border-color: linear-gradient(270deg, #00A09B 0%, #016C87 100%);        
        background-color: rgba(0, 160, 155, 0.19);
    }
    &[disabled] {
        border-color: #BBBBBB;
        color: #BBBBBB;
        &:hover {
            border-color: #BBBBBB;
            background-color: #fff;
            cursor: not-allowed;
        }
    }
}
.btn-tertiary {
    background-color: #DA6A66;   
    &:hover {
        background: linear-gradient(274.59deg, #DA6A66 -10.74%, #C54B47 118.03%);
        &[disabled] {
            border-color: #BBBBBB;
            color: #BBBBBB;
            &:hover {
                border-color: #BBBBBB;
                background-color: #fff;
                cursor: not-allowed;
            }
        }
    }
}
.btn-size-sm {
    font-size: 16px;
    font-weight: normal;
    width: 180px;
    height: 40px;
}
.btn-size-lg {
    font-size: 18px;
    font-weight: 400;
    width: 354px;
    height: 54px;
}
